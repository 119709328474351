



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ClientAppSetting,
  ClientAppSettingKind,
  CLIENT_APP_SETTING_KIND_DEFAULT,
  CLIENT_APP_SETTING_DEFAULT,
  ClientApp,
} from '@/models/client/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Context } from '@/api/ApiClientV2';
import SettingForm from '@/apps/device/components/SettingForm.vue';
import { deepCopy } from '@/util/util';
import { initClientApp } from '@/apps/init';
import { clientAppSettingSchemas } from './schema';

@Component({
  components: {
    SettingForm,
  },
})
export default class ClientAppSettings extends Vue {
  @Prop({ required: true }) clientApp!: string;

  clientAppObject: ClientApp | null = null;

  settings: ClientAppSetting[] = [];
  settingKinds: ClientAppSettingKind[] = [];
  destroySubject = new Subject<void>();
  clientAppSettingSchemas: any = null;

  mounted() {
    this.getSettings();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getSettings();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onSettingSaved() {
    this.$apiv2.refreshData();
  }

  async getSettings() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.clientAppObject = await this.$apiv2.get<ClientApp>(
        ClientApp,
        this.clientApp,
      );
      this.clientAppSettingSchemas = await clientAppSettingSchemas();
      this.settingKinds = await this.getSettingKinds();
      this.settings = await ClientApp.getSettings(this.$apiv2, this.clientApp);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  getSchema(setting: ClientAppSetting) {
    if (this.clientAppObject?.view_id !== undefined) {
      return this.clientAppSettingSchemas?.[this.clientAppObject.view_id]?.[
        setting.key
      ];
    }
  }

  async onDeleteSetting(setting: ClientAppSetting) {
    const key = setting.key;
    const id = setting.id;
    const object_seq = setting.object_seq;
    if (id === '0') {
      this.getSettings();
      return;
    }
    const loadingComponent = this.$buefy.loading.open({});
    try {
      if (object_seq === undefined) {
        throw new Error(
          'Setting has invalid object_seq. Please refresh the page.',
        );
      }
      await ClientApp.deleteSetting(
        this.$apiv2,
        this.clientApp,
        key,
        object_seq,
      );
      await this.getSettings();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  addSetting() {
    this.settings.push(deepCopy(CLIENT_APP_SETTING_DEFAULT));
  }

  async getSettingKinds(): Promise<ClientAppSettingKind[]> {
    try {
      const context: Context = {
        filter: {
          client_app: this.clientApp,
        },
        pagination: {},
      };
      return await this.$apiv2.getListItems<ClientAppSettingKind>(
        ClientAppSettingKind,
        context,
      );
    } catch (error) {
      this.$errorHandler.handleError(error);
      return Promise.resolve([]);
    }
  }

  async addDefaultSettingKind() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      await this.$apiv2.create(ClientAppSettingKind, {
        ...CLIENT_APP_SETTING_KIND_DEFAULT,
        name: 'default',
        handle: 'default',
        client_app: this.clientApp,
      });
      this.settingKinds = await this.getSettingKinds();
      this.$buefy.toast.open({
        message: this.$tc('common.createSuccess'),
        type: 'is-success',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  get hasDefaultSettings(): boolean {
    return (
      this.clientAppObject?.view_id === 'web-device' ||
      this.clientAppObject?.view_id === 'web-data' ||
      this.clientAppObject?.view_id === 'web-monitoring' ||
      this.clientAppObject?.view_id === 'web-study'
    );
  }

  async addDefaultSettings() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      if (this.clientAppObject?.view_id) {
        await initClientApp(this.clientAppObject);
        this.defaultSettingsConfirmed();
      }
      // add other client apps here if applicable
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    this.$apiv2.refreshData();
    loadingComponent.close();
  }

  defaultSettingsConfirmed() {
    this.$buefy.toast.open({
      message: 'Default settings successfully added',
      type: 'is-success',
    });
  }
}
