





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getChannelIdText,
  getFirmwareVersionFromId,
} from '@/models/device/helpers';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import {
  UpdateRequestEvent,
  FirmwareVersionEvent,
  DeviceEventType,
  UpdateRequestState,
} from '@/models/device/interfaces';
import { DeviceEventLog } from '@/models/device/models';

@Component({
  props: {
    updateResults: {
      required: true,
    },
    device: {
      required: true,
    },
  },
  components: {
    MultilineTooltip,
  },
})
export default class UpdateRequestSequence extends Vue {
  updateResults: UpdateRequestEvent[];
  firmwareResults: FirmwareVersionEvent[];
  currentChannel = 'Loading...';
  currentFirmware = 'Loading...';
  targetChannel = 'Loading...';
  targetFirmware = 'Loading...';
  targetDeliveryProcedure = '';
  confirmFirmware = '';
  confirmTime = '';

  @Watch('updateResults')
  onUpdateResultsChanged() {
    this.getCurrent();
    this.getTarget();
    this.getFirmwareVersionEvent();
  }

  mounted() {
    this.onUpdateResultsChanged();
  }

  async getCurrent() {
    if (this.updateResults.length > 0) {
      const result = await Promise.all(
        [
          getChannelIdText(
            this.updateResults[0].payload.fields.current_channel,
            this.$apiv2,
          ),
          getFirmwareVersionFromId(
            this.updateResults[0].payload.fields.current_firmware,
            this.$apiv2,
          ),
        ].map(p => {
          return p.catch(err => {
            this.handleError(err);
          });
        }),
      );
      this.currentChannel = result[0] || 'n/a';
      this.currentFirmware = result[1] || 'n/a';
    }
  }

  async getTarget() {
    if (this.updateResults.length > 0) {
      this.targetDeliveryProcedure = this.updateResults[0].payload.fields.delivery_procedure_id_text;
      const result = await Promise.all(
        [
          getChannelIdText(
            this.updateResults[0].payload.fields.target_channel,
            this.$apiv2,
          ),
          getFirmwareVersionFromId(
            this.updateResults[0].payload.fields.target_firmware,
            this.$apiv2,
          ),
        ].map(p => {
          return p.catch(err => {
            this.handleError(err);
          });
        }),
      );
      this.targetChannel = result[0] || 'n/a';
      this.targetFirmware = result[1] || 'n/a';
    }
  }

  async getFirmwareVersionEvent() {
    if (this.updateResults.length > 0) {
      const last = this.updateResults.length - 1;
      const start_time = new Date(this.updateResults[0].time);
      start_time.setSeconds(start_time.getSeconds() - 1);
      const end_time = new Date(this.updateResults[last].time);
      end_time.setSeconds(end_time.getSeconds() + 1);
      const queryFirmware = {
        device: this.$props.device,
        page: 1,
        page_size: 1,
        event_type: DeviceEventType.FIRMWARE_VERSION,
        start_time: start_time.toISOString(),
        end_time: end_time.toISOString(),
      };
      try {
        const events = await DeviceEventLog.queryEvents<FirmwareVersionEvent>(
          queryFirmware,
        );
        const results = events.results;
        if (results.length) {
          this.confirmFirmware = results[0].payload.fields.firmware_version;
          this.confirmTime = results[0].time;
        }
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  getSuccess(event: UpdateRequestEvent): boolean {
    if (
      event?.payload?.fields?.state === UpdateRequestState.UP_TO_DATE ||
      event?.payload?.fields?.state === UpdateRequestState.READY ||
      event?.payload?.fields?.state === UpdateRequestState.QUEUED ||
      event?.payload?.fields?.state === UpdateRequestState.CREATED ||
      event?.payload?.fields?.state === UpdateRequestState.SUCCESS ||
      event?.payload?.fields?.state === UpdateRequestState.CONFIRMED
    ) {
      return true;
    } else {
      return false;
    }
  }

  getProcessing(event: UpdateRequestEvent): boolean {
    return event?.payload?.fields?.state === UpdateRequestState.PROCESSING;
  }

  getTooltip(event: UpdateRequestEvent): string {
    return event?.payload?.fields
      ? `Event Trigger: ${event?.payload?.fields?.event_trigger}\nState: ${event?.payload?.fields?.state}`
      : '';
  }

  handleError(err) {
    console.log(this.$errorHandler.errorToString(err));
  }
}
