import { Model } from '@/models/device/models';
import { LifeCycleState } from '@/models/core/base';
import { PRODUCTS } from './products';

export const MODELS: Model[] = [
  {
    id: 'model_a',
    object_state: LifeCycleState.Approved,
    name: 'Model A',
    brief: 'This is model A',
    product: PRODUCTS[0].id,
    legacy_target_id: '',
    _permissions: {
      edit: true,
      view: true,
    },
  },
  {
    id: 'model_b',
    object_state: LifeCycleState.Approved,
    name: 'Model B',
    brief: 'This is model B',
    product: PRODUCTS[1].id,
    legacy_target_id: '',
    _permissions: {
      edit: true,
      view: true,
    },
  },
  {
    id: 'model_c',
    object_state: LifeCycleState.Approved,
    name: 'Model C',
    brief: 'This is model C',
    product: PRODUCTS[2].id,
    legacy_target_id: '',
    _permissions: {
      edit: true,
      view: true,
    },
  },
];
