import moment from 'moment';
import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  CellType,
  FormConfig,
} from '@/models/core/base';
import { ApiClientV2, Annotation, RelatedAnnotation } from '@/api/ApiClientV2';
import {
  DEVICE_SESSION_CONFIG_DEFAULT,
  DATA_APPLICATION_DEFAULT,
  STREAM_DEFAULT,
  DATA_SOURCE_TEMPLATE_DEFAULT,
  DEVICE_RELATION_DEFAULT,
  OUTPUT_DEFAULT,
} from './defaults';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';
import { Device, DeviceState, DeviceEventLog } from '../device/models';
import { deepCopy } from '@/util/util';
import { DeviceAssignment, Participant } from '../study/models';
import { translate } from '@/lang/setup';

export interface DeviceCreate {
  setting_value?: any;
  setting_key?: string;
  setting_kind_handle?: string;
}

export interface DeviceDetailComponent {
  component: string;
  name?: string;
  options?: any;
}

export interface DeviceModel {
  name?: string;
  id: string;
  role?: string;
  device_session_config?: string;
  device_create?: DeviceCreate[];
  device_detail?: DeviceDetailComponent[];
}

export class DataApplication extends TransientBaseObject {
  organisation: string;
  handle: string;
  name: string;
  description: string;
  static defaultViewId = 'web-data';
  static apiUrl = 'data/application';
  static useApiClientV2 = true;
  static langPath = 'data.application';
  static objectType = 'application';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'handle',
    },
    {
      key: 'description',
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'name',
      formProperties: {
        class: 'width-400',
      },
    },
    {
      key: 'handle',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
    {
      key: 'description',
      required: false,
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DATA_APPLICATION_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...DATA_APPLICATION_DEFAULT },
    };
  }
}

export class Output extends TransientBaseObject {
  stream: string;
  handle: string;
  plugin_id: string;
  config: any;
  config_valid?: boolean;
  static defaultViewId = 'web-data';
  static apiUrl = 'data/output';
  static useApiClientV2 = true;
  static langPath = 'data.output';
  static objectType = 'output';
  static listFields: ListModelField[] = [
    {
      key: 'handle',
      sortable: true,
    },
    {
      key: 'plugin_id',
    },
    {
      key: 'config_valid',
      cellType: CellType.TAG,
      class: row => {
        if (row.config_valid === 'valid') {
          return 'is-success';
        } else {
          return 'is-danger';
        }
      },
    },
  ];
  static get ancestors() {
    return [
      {
        modelClass: Stream,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }
  static fields: ModelField[] = [
    {
      key: 'handle',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
    {
      key: 'config',
      formFieldType: FormFieldType.JSON_FIELD,
      required: false,
      undotized: true,
    },
  ];
  static get annotations(): Annotation<Output>[] {
    return [
      {
        // config_valid is only available when getting a single output form the server
        key: 'config_valid',
        callback: async (ouputIn: Output, api: ApiClientV2) => {
          try {
            const output = await api.get<Output>(Output, ouputIn.id);
            return {
              id: output.id,
              annotations: {
                config_valid: output.config_valid ? 'valid' : 'invalid',
              },
            };
          } catch (err) {
            return {
              id: ouputIn.id,
              annotations: {
                config_valid: '',
              },
            };
          }
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(OUTPUT_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...OUTPUT_DEFAULT },
    };
  }
}

export class Stream extends TransientBaseObject {
  application: string;
  handle: string;
  stream_id_filter: any[];
  role_filter: any[];
  model_filter: any[];
  static defaultViewId = 'web-data';
  static apiUrl = 'data/stream';
  static useApiClientV2 = true;
  static langPath = 'data.stream';
  static objectType = 'stream';
  static listFields: ListModelField[] = [
    {
      key: 'handle',
      sortable: true,
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'handle',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
    {
      key: 'stream_id_filter',
      formFieldType: FormFieldType.JSON_FIELD,
      required: false,
      undotized: true,
    },
    {
      key: 'role_filter',
      formFieldType: FormFieldType.JSON_FIELD,
      required: false,
      undotized: true,
    },
    {
      key: 'model_filter',
      formFieldType: FormFieldType.JSON_FIELD,
      required: false,
      undotized: true,
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(STREAM_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...STREAM_DEFAULT },
    };
  }
}

export class DeviceRelation extends TransientBaseObject {
  application: string;
  role: string;
  device: string;
  static defaultViewId = 'web-data';
  static apiUrl = 'data/device-relation';
  static useApiClientV2 = true;
  static langPath = 'data.deviceRelation';
  static objectType = 'device-relation';
  static listFields: ListModelField[] = [
    {
      key: 'device_name',
      sortable: true,
      searchQuery: 'device_name_search',
    },
    {
      key: 'device_device_id',
    },
    {
      key: 'role',
      sortable: true,
    },
    {
      key: 'last_seen',
    },
    {
      key: 'health',
      cellType: CellType.TAG,
      class: row => {
        if (row.health === 'OK') {
          return 'is-success';
        }
        return 'is-warning';
      },
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'device_ids',
      required: false,
      formFieldType: FormFieldType.INPUT,
      placeholder: '00:12:23:42:01,00:31:A3:32:23',
      formProperties: {
        inputType: 'textarea',
        icon: '',
        message:
          'Device IDs can be entered using a comma-separated list. ' +
          '(e.g. 00:12:23:42:01,00:31:A3:32:23), separated by line breaks or a combination of both.' +
          'If the devices do not exist yet in the device app, they will be created automatically.',
      },
    },
  ];
  static tabFields: ModelField[][] = [];
  static get joins(): RelatedAnnotation<DeviceRelation>[] {
    return [
      {
        relatedModelClass: Device,
        relatedObjectProperty: 'name',
      },
      {
        relatedModelClass: Device,
        relatedObjectProperty: 'device_id',
      },
    ];
  }
  static get annotations(): Annotation<DeviceRelation>[] {
    return [
      {
        key: 'health',
        callback: async (deviceRelation: DeviceRelation, api: ApiClientV2) => {
          try {
            if (deviceRelation.role === 'gateway') {
              const deviceState = await api.find<DeviceState>(DeviceState, {
                device: deviceRelation.device,
                application: deviceRelation.application,
              });
              if (deviceState?.data?.last_health_check) {
                const last_health_check = new Date(
                  deviceState.data.last_health_check,
                );
                const fiveMinutes = 300000; // in milliseconds
                // last health check less than five minutes ago
                if (Date.now() - last_health_check.getTime() < fiveMinutes) {
                  return {
                    id: deviceRelation.id,
                    annotations: {
                      health: 'OK',
                    },
                  };
                }
              }
            }
            return {
              id: deviceRelation.id,
              annotations: {
                health: 'n/a',
              },
            };
          } catch (err) {
            console.log(err);
            return {
              id: deviceRelation.id,
              annotations: {
                health: 'n/a',
              },
            };
          }
        },
      },
      {
        key: 'last_seen',
        callback: async (deviceRelation: DeviceRelation) => {
          try {
            const lastSeen = await Device.getLastSeen(deviceRelation.device);
            return {
              id: deviceRelation.id,
              annotations: { last_seen: lastSeen },
            };
          } catch (err) {
            return {
              id: deviceRelation.id,
              annotations: { last_seen: translate('common.error') },
            };
          }
        },
      },
      {
        key: 'assigned_pid',
        callback: async (deviceRelation: DeviceRelation, api: ApiClientV2) => {
          let assigned_pid = '';
          let assigned_role = '';
          try {
            const deviceAssignment: DeviceAssignment = await api.find<DeviceAssignment>(
              DeviceAssignment,
              {
                device: deviceRelation.device,
                application: deviceRelation.application,
              },
            );
            const participant: Participant = await api.get<Participant>(
              Participant,
              deviceAssignment.patient,
            );
            assigned_pid = participant.pid;
            assigned_role = deviceAssignment.role;
            return {
              id: deviceRelation.id,
              annotations: { assigned_pid, assigned_role },
            };
          } catch (err) {
            return {
              id: deviceRelation.id,
              annotations: { assigned_pid, assigned_role },
            };
          }
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DEVICE_RELATION_DEFAULT);
  }

  static formConfig(): FormConfig {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...DEVICE_RELATION_DEFAULT },
    };
  }
}

export class BulkDeviceRelationImport {
  application: string;
  role: string;
  devices: string[];
}

export class DeviceSessionConfig extends TransientBaseObject {
  application: string;
  handle: string;
  name: string;
  description: string;
  expiration_policy: {
    closed: number;
    expired: number;
  };
  static defaultViewId = 'web-data';
  static apiUrl = 'data/device-session-config';
  static useApiClientV2 = true;
  static langPath = 'data.deviceSessionConfig';
  static objectType = 'device-session-config';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'handle',
    },
    {
      key: 'expiration_policy.closed',
    },
    {
      key: 'expiration_policy.expired',
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'name',
      formProperties: {
        class: 'width-400',
      },
    },
    {
      key: 'handle',
      formProperties: {
        class: 'width-400',
      },
    },
    {
      key: 'expiration_policy.closed',
      formFieldType: FormFieldType.NUMBER_FIELD,
      formProperties: {
        class: 'width-400',
        message:
          'Expiration time when in `closed` state in seconds. 1 Month maximum value. Default 1 day.',
      },
    },
    {
      key: 'expiration_policy.expired',
      formFieldType: FormFieldType.NUMBER_FIELD,
      formProperties: {
        class: 'width-400',
        message:
          'Expiration time when in `expired` state in seconds. 1 Month maximum value. Default 1 week.',
      },
    },
    {
      key: 'description',
      required: false,
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DEVICE_SESSION_CONFIG_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      // nested defaults -> need to parse
      model: { ...this.parseModel(DEVICE_SESSION_CONFIG_DEFAULT, this.fields) },
    };
  }
}

export class DataSourceTemplate extends TransientBaseObject {
  plugin_id: string;
  config: any;
  application: string;
  handle: string;
  static defaultViewId = 'web-data';
  static apiUrl = 'data/data-source-template';
  static useApiClientV2 = true;
  static langPath = 'data.dataSourceTemplate';
  static objectType = 'data-source-template';
  static listFields: ListModelField[] = [
    {
      key: 'handle',
      sortable: true,
    },
    {
      key: 'plugin_id',
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'handle',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
    {
      key: 'config',
      formFieldType: FormFieldType.JSON_FIELD,
      required: false,
      undotized: true,
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DATA_SOURCE_TEMPLATE_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...DATA_SOURCE_TEMPLATE_DEFAULT },
    };
  }
}

export class DataSource extends TransientBaseObject {
  template: string;
  parameters: any;
  hash: string;
  content: any | null;
  bg_task: string | null;
  is_prepared: boolean;
  output_type: DataSourceOutputType;
}

export enum DataSourceOutputType {
  TIME_SERIES = 'time-series',
  CSV_DOWNLOAD = 'csv-download',
  HDF5_DOWNLOAD = 'hdf5-download',
}

export class DataSourceParams {
  template: string;
  parameters: any;
  output_type: DataSourceOutputType;
}

export class TimeSeries extends TransientBaseObject {
  file: string;
  size: number;
  metadata: any;
  last_access: Date;
  application: string;
}

export class TimeSeriesData {
  y: string;
  min?: string;
  max?: string;
}

export class TimeSeriesTrace {
  name: string;
  key: string;
  data: TimeSeriesData;
}

export class TimeSeriesSegment {
  time: string[];
  [s: string]: any;
}

export class TimeSeriesResponse {
  range_max: string;
  range_min: string;
  traces: TimeSeriesTrace[];
  segments: TimeSeriesSegment[];
}

export class TimeSeriesLabel {
  start: Date;
  end: Date;
  label: string;
}

export interface TimeSeriesSelection {
  startDate: moment.Moment;
  endDate: moment.Moment;
}
