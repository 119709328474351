export const common = {
  orderingField: {
    setAtEnd: 'Set at end',
    setManually: 'Set manually',
  },
  avatar: {
    avatar: 'Avatar',
    currentAvatar: 'Current Avatar',
    resolutionLimit: 'Resolution larger than 10x10, smaller than 1024x1024',
    fileSizeLimit: 'Maximum file size allowed is 200kB',
  },
  fields: {
    description: {
      label: 'Brief description',
      placeholder: 'Description',
    },
  },
  date: 'Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  lastModified: 'Last Modified',
  never: 'Never',
  unknown: 'Unknown',
  error: 'Error',
  history: 'History',
  now: 'Now',
  clear: 'Clear',
  clickToSelect: 'Cick to select',
  selected: 'selected',
  overview: 'Overview',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  saveSuccess: 'Successfully saved',
  cancel: 'Cancel',
  reset: 'Reset',
  back: 'Back',
  delete: 'Delete',
  remove: 'Remove',
  deleteSuccess: 'Successfully deleted',
  detail: 'Detail',
  enable: 'Enable',
  disable: 'Disable',
  edit: 'Edit',
  actions: 'Actions',
  copy: 'Copy',
  copyDataText: 'Single click: Copy ID.\nDouble click: Copy Row Data.',
  copyToClipboard: 'Copy to clipboard',
  copySuccess: 'Successfully copied',
  copyError: 'Error while copying to clipboard',
  copiedEvent: 'Copied event',
  select: 'Select',
  deselect: 'Deselect',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  selection: 'Selection',
  apply: 'Apply',
  empty: 'Nothing here.',
  required: 'Required',
  loading: 'Loading',
  refreshData: 'Refresh data',
  refreshSuccess: 'Successfully refreshed',
  searchBy: 'Search by',
  search: 'Search',
  filter: 'Filter',
  filterBy: 'Filter by',
  noFilter: 'No filter',
  noSelection: 'No selection',
  noPrefix: 'No',
  found: 'found',
  moveUp: 'Move up',
  moveDown: 'Move down',
  show: 'Show',
  perPage: 'per page',
  number: 'Number',
  create: 'Create',
  createSuccess: 'Successfully created',
  leaveWithoutSaveQuestion:
    'Are you sure you want to leave without saving changes?',
  confirmDelete: 'Are you sure that you want to delete this object?',
  confirmRemove: 'Are you sure that you want to remove this object?',
  uploadForm: {
    dropToUpload: 'Drop files to upload',
    preview: 'Preview',
    name: 'Name',
    size: 'Size',
    status: 'Status',
    chooseFile: 'Choose file',
    noImage: 'No image',
    startUpload: 'Start Upload',
    stopUpload: 'Stop Upload',
    invalidExtension: 'Invalid extension',
  },
};
