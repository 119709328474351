var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navbarHidden),expression:"!navbarHidden"}],staticClass:"navbar dashboard-nav has-shadow",class:{ 'is-pushed': _vm.isPushed, 'dashboard-nav-logged-out': !_vm.isLoggedIn }},[_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"navbar-brand"},[_c('button',{class:_vm.menuButtonClass,on:{"click":_vm.switchReaderMode}},[(!_vm.readerModeEnabled)?_c('i',{staticClass:"mdi mdi-menu mdi-24px"}):_c('i',{staticClass:"mdi mdi-close mdi-24px"})]),_c('router-link',{staticClass:"is-hidden-mobile",attrs:{"to":{ name: 'overview' },"exact":""}},[(_vm.theme === 'usb')?_c('img',{staticClass:"nav-logo usb-logo",attrs:{"src":"/img/usb/usb.svg","alt":"Logo","height":"41"}}):_vm._e(),_c('img',{staticClass:"nav-logo",attrs:{"src":"/img/logo.png","alt":"Logo","height":"30"}})])],1),(_vm.isLoggedIn)?_c('div',{staticClass:"navbar-menu is-mobile"},[_c('div',{staticClass:"navbar-end"},[(_vm.showTime)?_c('div',{staticClass:"navbar-item"},[_c('current-time')],1):_vm._e(),(_vm.showRefresh)?_c('a',{staticClass:"navbar-item",attrs:{"disabled":""},on:{"click":function($event){return _vm.refreshData()}}},[_c('b-tooltip',{attrs:{"label":_vm.$tc('common.refreshData'),"position":"is-bottom"}},[(!_vm.refreshing)?_c('span',{staticClass:"icon is-medium navigationbar-icon"},[_c('i',{staticClass:"mdi mdi-refresh mdi-24px"})]):_c('span',{staticClass:"button is-loading is-text"})])],1):_vm._e(),(_vm.showLanguageSwitcher)?_c('LanguageSwitcher'):_vm._e(),(_vm.profile)?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable profile-dropdown"},[_c('a',{staticClass:"navbar-link"},[_c('figure',{staticClass:"image is-32x32",staticStyle:{"margin-right":"0.5em"}},[_vm._m(0)]),_vm._v(" "+_vm._s(_vm.displayName)+" ")]),_c('div',{staticClass:"navbar-dropdown is-right"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
                name: 'user-profile',
                params: {
                  id: _vm.profile.id,
                },
              }}},[_c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-account"})]),_c('span',[_vm._v(_vm._s(_vm.$tc('auth.profile')))])]),_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
                name: 'request_change_email',
                params: {
                  id: _vm.profile.id,
                  old_email: _vm.profile.email,
                },
              }}},[_c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-email"})]),_c('span',[_vm._v(_vm._s(_vm.$tc('auth.changeEmail')))])]),_c('router-link',{staticClass:"navbar-item",attrs:{"to":{ name: 'change-password' }}},[_c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-lock-outline"})]),_c('span',[_vm._v(_vm._s(_vm.$tc('auth.changePassword')))])]),_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.logout()}}},[_vm._m(1),_c('span',[_vm._v("Logout")])])],1)]):_vm._e()],1)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{attrs:{"src":_vm.getAvatar()}})},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-power"})])}]

export { render, staticRenderFns }