// device
import DeviceHistoryList from '@/apps/device/components/device/DeviceHistoryList.vue';
import DeviceSettings from '@/apps/device/components/device/DeviceSettings.vue';
import LinksList from '@/apps/device/components/device/LinksList.vue';
import DeviceStateView from '@/apps/device/components/device/DeviceStateView.vue';
import GatewaySoftwareLog from '@/apps/device/components/device/GatewaySoftwareLog.vue';
import UpdateRequestList from '@/apps/device/components/device/UpdateRequestList.vue';
// data
import EverionSettingsView from '@/apps/data/components/device/EverionSettingsView.vue';
import ActivityTrackerSettingsView from '@/apps/data/components/device/ActivityTrackerSettingsView.vue';
import ActivityTrackerReadoutProgressView from '@/apps/data/components/device/ActivityTrackerReadoutProgressView.vue';
import GatewayView from '@/apps/data/components/device/GatewayView.vue';
import GatewayEverionAssignmentView from '@/apps/data/components/device/GatewayEverionAssignmentView.vue';
import DeviceDataExplorer from '@/apps/data/components/device/DeviceDataExplorer.vue';
import DeviceAssignment from '@/apps/data/components/device/DeviceAssignment.vue';
// monitoring
import CustomThresholds from '@/apps/monitoring/components/CustomThresholds.vue';
import CreateTileLink from '@/apps/monitoring/components/CreateTileLink.vue';
// study
import ParticipantAssignment from '@/apps/data/components/device/ParticipantAssignment.vue';

export const deviceDetailComponents = {
  //device
  DeviceHistoryList,
  DeviceSettings,
  LinksList,
  DeviceStateView,
  GatewaySoftwareLog,
  UpdateRequestList,
  // data
  EverionSettingsView,
  ActivityTrackerSettingsView,
  ActivityTrackerReadoutProgressView,
  GatewayView,
  GatewayEverionAssignmentView,
  DeviceDataExplorer,
  DeviceAssignment,
  // monitoring
  CustomThresholds,
  CreateTileLink,
  // study
  ParticipantAssignment,
};
