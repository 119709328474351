import { study as studyEn } from '@/lang/en/study';

export const study: typeof studyEn = {
  participant: {
    prettyName: 'Teilnehmer | Teilnehmer',
    fields: {
      pid: 'PID',
      state: 'Status',
      meta: 'Meta',
      last_data: 'Letzte Daten',
    },
  },
  deviceassignment: {
    prettyName: 'Gerätezuordnung | Gerätezuordnungen',
    fields: {
      patient: 'Teilnehmer',
      patient_pid: 'PID',
      device_relation: 'Gerät',
      device_id: 'Gerät ID',
      device_name: 'Gerätename',
      role: 'Rolle',
      effective_time: 'Startzeit',
    },
    deleteExisting: 'Gerätezuordnung löschen',
    confirmDelete:
      'Sind Sie sicher, dass Sie diese Gerätezuordnung löschen wollen?',
  },
  dataassignment: {
    prettyName: 'Datenzuordnung | Datenzuordnungen',
    fields: {
      assignment_unassignment: 'Hinzufügen/Entfernen',
      add_data: 'Daten hinzufügen',
      remove_data: 'Daten entfernen',
      role: 'Rolle',
      start_date: 'Startzeit',
      end_date: 'Endzeit',
      device: 'Gerät',
    },
    confirmUnassign:
      'Sind Sie sicher, dass Sie diese Daten von diesem Teilnehmer entfernen möchten?',
    noDataAssigned:
      'Dieser Teilnehmer hat keine Daten von diesem Gerät in diesem Zeitraum.',
    alreadyDataAssigned:
      'Daten von diesem Gerät sind bereits dem Teilnehmer {pid} zugeordnet. Diesen Daten werden vom Teilnehmer {pid} entfernt. Möchten Sie fortfahren?',
    alreadyDataRoleAssigned:
      'Es sind bereits Daten vom Gerät "{device_name}" ({device_id}) mit der Rolle "{role}" diesem Teilnehmer zugeordnet. Sie können nicht nochmals zugeordnet werden.',
    previousRemoved: 'Datenzuordnung entfernt',
    updateSuccessful: 'Datenzuordnung erfolgreich hinzugefügt',
  },
  datalabel: {
    prettyName: 'Datenlabel | Datenlabel',
    fields: {
      application: 'Applikation',
      type: 'Typ',
      patient: 'Teilnehmer',
      device: 'Gerät',
      state: 'Status',
      start_time: 'Startzeit',
      end_time: 'Endzeit',
      meta: 'Meta',
    },
  },
  state: {
    current: 'Aktueller Status',
    next: 'Nächster Status',
  },
};
