import {
  DEVICE_DEFAULT,
  PRODUCT_DEFAULT,
  MODEL_DEFAULT,
  CLASSIFICATION_DEFAULT,
  DEVICE_SETTING_KIND_DEFAULT,
  DEVICE_STATE_DEFAULT,
} from '@/models/device/defaults';
import {
  ApiClientV2,
  RelatedAnnotation,
  Annotation,
  apiClientV2,
} from '@/api/ApiClientV2';
import {
  BaseObject,
  TransientBaseObject,
  ListModelField,
  CellType,
} from '@/models/core/base';
import {
  compareFirmwareAndUpdateEvents,
  getFirmwareVersionFromDeviceVersionInfoEvent,
} from './helpers';
import { deepCopy } from '@/util/util';
import {
  DeviceEventType,
  DeviceEvent,
  DeviceEventLogEventQuery,
  EventLogResult,
  DeviceEventLogLabelQuery,
  EventLabel,
  FirmwareVersionEvent,
  UpdateRequestEvent,
} from './interfaces';
import { Dictionary } from '@/util/interfaces';
import { translate } from '@/lang/setup';

export class Product extends BaseObject {
  name: string;
  organisation: string;
  static apiUrl = 'product';
  static langPath = 'device.product';
  static objectType = 'product';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'create_time',
      transform: (createTime: string) => {
        return new Date(createTime).toLocaleString();
      },
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(PRODUCT_DEFAULT);
  }
}

export class Model extends BaseObject {
  name: string;
  brief: string;
  product: string;
  legacy_target_id: string;
  handle?: string;
  system_item_handle?: string;
  static apiUrl = 'model';
  static langPath = 'device.model';
  static objectType = 'model';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'brief',
    },
    {
      key: 'handle',
      cellType: CellType.CODE,
    },
    {
      key: 'product_name',
    },
  ];
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: false,
        mainParent: true,
      },
    ];
  }
  static get joins(): RelatedAnnotation<Model>[] {
    return [
      {
        relatedModelClass: Product,
        relatedObjectProperty: 'name',
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(MODEL_DEFAULT);
  }
}

export class Classification extends BaseObject {
  name: string;
  model: string;
  handle: string;
  static apiUrl = 'classification';
  static langPath = 'device.classification';
  static objectType = 'classification';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'handle',
      cellType: CellType.CODE,
    },
  ];
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: false,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(CLASSIFICATION_DEFAULT);
  }
}

export class DeviceSettingKind extends TransientBaseObject {
  name: string;
  model: string;
  handle: string;
  description: string;
  static apiUrl = 'device-setting-kind';
  static langPath = 'device.deviceSettingKind';
  static objectType = 'device-setting-kind';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'handle',
      cellType: CellType.CODE,
    },
    {
      key: 'description',
    },
  ];
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: false,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DEVICE_SETTING_KIND_DEFAULT);
  }
}

export interface DeviceDetails {
  device_id: string;
  name?: string;
  classification_id?: string;
}

export class Device extends TransientBaseObject {
  model: string;
  device_id: string;
  classification: string;
  name?: string;
  devices?: string[] | DeviceDetails[];

  static apiUrl = 'device';
  static langPath = 'device.device';
  static objectType = 'device';
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: true,
      },
      {
        modelClass: Model,
        requiredForFilter: false,
        mainParent: true,
      },
      {
        modelClass: Classification,
        requiredForFilter: false,
      },
    ];
  }
  static listFields: ListModelField[] = [
    {
      key: 'name',
      cellType: CellType.EDITABLE_CELL,
      searchQuery: 'name_search',
    },
    {
      key: 'device_id',
      sortable: true,
      searchQuery: 'device_id_search',
    },
    {
      key: 'create_time',
      transform: (createTime: string) => {
        return new Date(createTime).toLocaleString();
      },
    },
    {
      key: 'model_name',
    },
    {
      key: 'firmware_version',
      cellType: [CellType.TAG, CellType.TAG, CellType.TAG],
      undotized: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      class: [() => 'is-success', () => 'is-warning', () => 'is-danger'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltip: [
        row => {
          return {
            label:
              row[`firmware_tooltip[0]`] ||
              'No firmware version information available',
          };
        },
        row => {
          return {
            label:
              row[`firmware_tooltip[1]`] ||
              'No firmware version information available',
          };
        },
        row => {
          return {
            label:
              row[`firmware_tooltip[2]`] ||
              'No firmware version information available',
          };
        },
      ],
    },
    {
      key: 'last_seen',
    },
  ];
  static get joins(): RelatedAnnotation<Device>[] {
    return [
      {
        relatedModelClass: Classification,
        relatedObjectProperty: 'name',
      },
      {
        relatedModelClass: Model,
        relatedObjectProperty: 'name',
      },
    ];
  }
  static get annotations(): Annotation<Device>[] {
    return [
      {
        key: 'firmware_version',
        callback: async (device: Device, api: ApiClientV2) => {
          try {
            const queryFirmware = {
              device: device.id,
              page: 1,
              page_size: 1,
              event_type: DeviceEventType.FIRMWARE_VERSION,
            };
            const queryUpdate = {
              device: device.id,
              page: 1,
              page_size: 10,
              event_type: DeviceEventType.UPDATE_REQUEST,
            };

            const events = await Promise.all([
              DeviceEventLog.queryEvents<FirmwareVersionEvent>(queryFirmware),
              DeviceEventLog.queryEvents<UpdateRequestEvent>(queryUpdate),
            ]);

            const hasFirmwareUpdateEvents =
              events[0].results.length || events[1].results.length;

            if (!hasFirmwareUpdateEvents) {
              const firmwareVersion = await getFirmwareVersionFromDeviceVersionInfoEvent(
                device.id,
              );
              if (firmwareVersion) {
                return {
                  id: device.id,
                  annotations: {
                    firmware_version: [firmwareVersion, '', ''],
                    firmware_tooltip: [
                      `Firmware version ${firmwareVersion}`,
                      '',
                      '',
                    ],
                  },
                };
              } else {
                return {
                  id: device.id,
                  annotations: {
                    firmware_version: ['', 'n/a', ''],
                  },
                };
              }
            }

            const versionResult = compareFirmwareAndUpdateEvents(
              events[0].results,
              events[1].results,
              api,
            );

            // extract information to display in device list
            return {
              id: device.id,
              annotations: {
                firmware_version: [
                  await versionResult.confirmed.version,
                  await versionResult.unconfirmed.version,
                  await versionResult.failed.version,
                ],
                firmware_tooltip: [
                  `Version: ${await versionResult.confirmed
                    .version}\nUpdate State: ${
                    versionResult.confirmed.state
                  }\nChannel: ${await versionResult.confirmed
                    .channel}\n${new Date(
                    versionResult.confirmed.time,
                  ).toLocaleString()}`,
                  `Version: ${await versionResult.unconfirmed
                    .version}\nUpdate State: ${
                    versionResult.unconfirmed.state
                  }\nChannel: ${await versionResult.unconfirmed
                    .channel}\n${new Date(
                    versionResult.unconfirmed.time,
                  ).toLocaleString()}`,
                  `Version: ${await versionResult.failed
                    .version}\nUpdate State: ${
                    versionResult.failed.state
                  }\nChannel: ${await versionResult.failed.channel}\n${new Date(
                    versionResult.failed.time,
                  ).toLocaleString()}`,
                ],
              },
            };
          } catch (error) {
            return {
              id: device.id,
              // return error as second element in array to be shown as warning
              annotations: {
                firmware_version: ['', translate('common.error'), ''],
              },
            };
          }
        },
      },
      {
        key: 'last_seen',
        callback: async (device: Device) => {
          try {
            const lastSeen = await Device.getLastSeen(device.id);
            return {
              id: device.id,
              annotations: { last_seen: lastSeen },
            };
          } catch (err) {
            return {
              id: device.id,
              annotations: { last_seen: translate('common.error') },
            };
          }
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DEVICE_DEFAULT);
  }

  /**
   * Get a single setting of a device
   * @param apiClient
   * @param deviceId
   * @param key
   */
  static async getSetting(
    apiClient: ApiClientV2,
    deviceId: string,
    key: string,
  ): Promise<DeviceSetting> {
    const response = await apiClient.customGet(
      `device/${deviceId}/setting/${key}/`,
    );
    return response;
  }

  /**
   * Get list of all settings of device
   * @param apiClient
   * @param deviceId
   */
  static async getSettings(
    apiClient: ApiClientV2,
    deviceId: string,
  ): Promise<DeviceSetting[]> {
    const response = await apiClient.customGet(`device/${deviceId}/setting/`);
    return response.results;
  }

  /**
   * Write value to device setting
   * @param apiClient
   * @param device
   * @param key
   * @param value
   * @param kind
   */
  static async setSetting(
    apiClient: ApiClientV2,
    device: string,
    key: string,
    value: any,
    kind?: string,
  ): Promise<void> {
    try {
      await apiClient.customPost(`device/${device}/setting/`, {
        key,
        value,
        kind,
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.kind) {
        // kind required for new setting
        const deviceObject = await apiClient.get<Device>(Device, device);
        const deviceSettingKind: DeviceSettingKind = await apiClient.getOrCreate<DeviceSettingKind>(
          DeviceSettingKind,
          {
            ...DEVICE_SETTING_KIND_DEFAULT,
            model: deviceObject.model,
            handle: 'default',
            name: 'default',
          },
          ['model', 'handle'],
        );
        // post again with kind
        await apiClient.customPost(`device/${device}/setting/`, {
          key,
          value,
          kind: deviceSettingKind.id,
        });
      } else {
        throw error;
      }
    }
  }

  /**
   * Delete a setting of device
   * @param apiClient
   * @param deviceId
   * @param key
   */
  static async deleteSetting(
    apiClient: ApiClientV2,
    deviceId: string,
    key: string,
  ): Promise<void> {
    await apiClient.customDelete(`device/${deviceId}/setting/${key}/`);
  }

  static async getName(device_id: string, model: string): Promise<string> {
    const device = await apiClientV2.find<Device>(Device, { device_id, model });
    return device.name;
  }

  static async getLastSeen(id: string): Promise<string> {
    const query: DeviceEventLogEventQuery = {
      device: id,
      page: 1,
      page_size: 1,
    };
    const event = await DeviceEventLog.queryEvents(query);
    const results = event.results;
    if (results.length === 0) {
      return translate('common.never');
    } else if (results[0].time) {
      return new Date(results[0].time).toLocaleString();
    } else {
      return translate('common.unknown');
    }
  }
}

export class CreateDeviceSerializer extends Device {
  sync?: boolean;
}

export class DeviceSetting extends TransientBaseObject {
  key: string;
  value: any;
  last_modified?: string;
  kind: string;
  object_seq?: number;
}

export class DeviceState extends TransientBaseObject {
  application: string;
  device: string;
  data: {
    [key: string]: any;
  };
  static apiUrl = 'data/device-state';

  static get defaultModel() {
    return deepCopy(DEVICE_STATE_DEFAULT);
  }
}

/* event log */

export class DeviceEventLog {
  static async queryEvents<T = DeviceEvent>(
    query: DeviceEventLogEventQuery,
  ): Promise<EventLogResult<T>> {
    if (!query.device && !query.model) {
      throw new Error('Must filter for either model or device.');
    }
    const result: EventLogResult<T> = await apiClientV2.customGet(
      'device-event-log/query-events',
      { ...query },
    );
    return result;
  }

  static async queryLabels(
    query: DeviceEventLogLabelQuery,
  ): Promise<EventLogResult<EventLabel>> {
    const result: EventLogResult<EventLabel> = await apiClientV2.customGet(
      'device-event-log/query-labels',
      { ...query },
    );
    return result;
  }
}
