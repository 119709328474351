






































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { DataApplication, DeviceRelation } from '@/models/data/models';
import { DATA_APPLICATION_DEFAULT } from '@/models/data/defaults';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import DeviceRelationAssignList from './DeviceRelationAssignList.vue';
import {
  getDeviceSessionConfig,
  getDeviceAssignments,
  addDevices,
  deviceIdsToDeviceRelations,
} from '@/apps/data/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deepCopy } from '@/util/util';
import { Context } from '@/api/ApiClientV2';
import DeviceAssignmentRole from './DeviceAssignmentRole.vue';
import { DEVICE_DEFAULT } from '@/models/device/defaults';

@Component({
  components: {
    DeviceRelationAssignList,
    DeviceAssignmentRole,
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeviceAssignment extends Vue {
  @Prop({ required: true }) device: string;
  @Prop({ required: true }) appId: string;
  /**
   * Options of this component:
   * - role: role of the source device, e.g. 'everion'
   * - devicePlugin: gateway device plugin name, e.g. 'device-plugin-everion'
   * - checkOthers: whether to check if devices are assigned to other gateways
   */
  @Prop({ required: true }) options: {
    role?: string;
    roles?: string[];
    devicePlugin: string;
    checkOthers?: boolean;
  };

  gateway: Device = deepCopy(DEVICE_DEFAULT);
  application: DataApplication = deepCopy(DATA_APPLICATION_DEFAULT);
  loading = true;
  // devices assigned to this gateway
  selectedRelations: string[] = [];
  // devices assigned to other gateways
  assignedRelations: string[] = [];
  roles: string[] = [];

  destroySubject = new Subject<void>();

  async mounted() {
    this.validateOptions();
    if (this.options.role) {
      this.roles = [this.options.role];
    } else {
      this.roles = this.options.roles;
    }
    await this.load();
    this.loading = false;
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  validateOptions() {
    try {
      if (!this.options.devicePlugin) {
        throw new Error(
          `"devicePlugin" missing in options of device-assignment component.`,
        );
      }
      if (!this.options.role && !this.options.roles?.length) {
        throw new Error(
          `Either "role" or "roles" have to be in options of device-assignment component.`,
        );
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  async load() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.gateway = await this.$apiv2.get<Device>(Device, this.device);
      this.application = await this.$apiv2.get<DataApplication>(
        DataApplication,
        this.appId,
      );
      await this.getDeviceAssignments();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  async getDeviceAssignments() {
    const assignments = await getDeviceAssignments(
      this.$apiv2,
      this.appId,
      this.device,
      this.options.devicePlugin,
      this.options.checkOthers,
    );
    this.selectedRelations = (
      await deviceIdsToDeviceRelations(this.appId, assignments.selected)
    )
      .filter(r => !!r.id)
      .map(rel => rel.id);

    this.assignedRelations = (
      await deviceIdsToDeviceRelations(this.appId, assignments.assigned)
    )
      .filter(r => !!r.id)
      .map(rel => rel.id);

    if (this.options.checkOthers) {
      try {
        // check for double assignments
        this.selectedRelations.forEach(r => {
          if (this.assignedRelations.find(a => a === r)) {
            throw new Error(`Device ${r} is assigned twice.`);
          }
        });
      } catch (error) {
        this.$errorHandler.handleError(error);
      }
    }
  }

  setSelectedRelations(devices: string[]) {
    if (devices.length > 0) {
      this.selectedRelations = Array.from(
        new Set([...this.selectedRelations, ...devices]),
      );
    }
  }

  unselect(device: string) {
    this.selectedRelations = this.selectedRelations.filter(d => d !== device);
  }

  async updateSettings() {
    // try / catch handled by save
    const clientAppId: string = this.$store.getters['global/selectedClientApp']
      .id;
    const deviceSessionConfig = await getDeviceSessionConfig(
      this.$apiv2,
      clientAppId,
      this.roles?.[0],
    );
    if (!deviceSessionConfig) {
      throw new Error(
        `"device_session_config" missing in client app settings: device_models > role: ${this.options.role}`,
      );
    }
    await addDevices(
      this.$apiv2,
      this.appId,
      this.device,
      this.selectedRelations,
      deviceSessionConfig,
      this.options.devicePlugin,
    );
  }

  async selectAll() {
    const loading = this.$buefy.loading.open({});
    try {
      const context: Context = {
        filter: {
          application: this.appId,
          role: this.options.role,
        },
        pagination: {
          page: 1,
          pageSize: 500,
        },
      };
      const response = await this.$apiv2.getList<DeviceRelation>(
        DeviceRelation,
        context,
      );
      if (response.size === 500) {
        this.$buefy.toast.open({
          message: 'Only first 500 selected',
          type: 'is-warning',
        });
      }
      const selecteableIds = response.results
        .map(r => r.id)
        .filter(id => this.assignedRelations.find(r => r === id) === undefined);
      this.setSelectedRelations(selecteableIds);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  unselectAll() {
    this.selectedRelations = [];
  }

  async save() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      await this.updateSettings();
      await this.$apiv2.refreshData();
    } catch (error) {
      this.$errorHandler.handleError(error);
      await this.$apiv2.refreshData();
    }
    loadingComponent.close();
  }
}
