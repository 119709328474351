



















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceRelation } from '@/models/data/models';
import { Context } from '@/api/ApiClientV2';
import DeviceRelationAssignList from './DeviceRelationAssignList.vue';

@Component({
  components: {
    DeviceRelationAssignList,
  },
})
export default class DeviceAssignmentRole extends Vue {
  @Prop({ required: true }) device: string;
  @Prop({ required: true }) appId: string;
  @Prop({ required: true }) role: string;
  @Prop({ required: true }) selectedRelations: string[];
  @Prop({ required: true }) assignedRelations: string[];

  async selectAll() {
    const loading = this.$buefy.loading.open({});
    try {
      const context: Context = {
        filter: {
          application: this.appId,
          role: this.role,
        },
        pagination: {
          page: 1,
          pageSize: 500,
        },
      };
      const response = await this.$apiv2.getList<DeviceRelation>(
        DeviceRelation,
        context,
      );
      if (response.size === 500) {
        this.$buefy.toast.open({
          message: 'Only first 500 selected',
          type: 'is-warning',
        });
      }
      const selecteableIds = response.results
        .map(r => r.id)
        .filter(id => this.assignedRelations.find(r => r === id) === undefined);
      this.$emit('row-selection', selecteableIds);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
