import MockAdapter from 'axios-mock-adapter';
import { DEVICE_STATE } from './data/device_state';

function addIdAndPermissions(object: any) {
  object.id = 'id_' + object.name;
  object._permissions = ['view'];
}

export class Endpoint {
  objectType: string;
  url: string;
  mockData: any[];
  baseUrl = '/api/v1/';
  isList = true;

  constructor(objectType, mockData, isList?) {
    this.objectType = objectType;
    this.url = this.baseUrl + objectType;
    this.mockData = mockData;
    if (isList !== undefined) {
      this.isList = isList;
    }
  }
}

const defaultEndpoints = [];

defaultEndpoints.push(
  new Endpoint(
    'data/device-state/find?&device=80b260d1-d9b7-49f3-bb39-36dbfd8ee683&application=202f8fdb-4a2c-4a63-bc28-4eae93fde7a8',
    DEVICE_STATE[1],
    false,
  ),
);
/*
defaultEndpoints.push(new Endpoint('participant/1/', PARTICIPANTS[0]))
defaultEndpoints.push(new Endpoint('participant/2/', PARTICIPANTS[1]))
defaultEndpoints.push(new Endpoint('gateway/?organisation=da74894f-a486-425b-9425-7c823cdd53ca', DEVICES))
defaultEndpoints.push(new Endpoint('gateway/1/', DEVICES[0]))
defaultEndpoints.push(new Endpoint('classification/', CLASSIFICATIONS))
defaultEndpoints.push(new Endpoint('organisation/', ORGANISATIONS))
defaultEndpoints.push(new Endpoint('channel/', CHANNELS))
defaultEndpoints.push(new Endpoint('model/', MODELS))
defaultEndpoints.push(new Endpoint('firmware/', FIRMWARES))
defaultEndpoints.push(new Endpoint('product/', PRODUCTS))
defaultEndpoints.push(new Endpoint('delivery-method/', DELIVERY_METHODS))
defaultEndpoints.push(new Endpoint('delivery-procedure/', DELIVERY_PROCEDURES))
defaultEndpoints.push(new Endpoint('auth/info', AUTH_INFO))
defaultEndpoints.push(new Endpoint('client-app/?organisation=e1d28c8d-7162-40ad-a826-a9cc8c027bff',
CLIENT_APPS_0))
defaultEndpoints.push(new Endpoint('client-app/1/', CLIENT_APPS_0[0]))
defaultEndpoints.push(new Endpoint('client-app/2/', CLIENT_APPS_0[1]))
defaultEndpoints.push(new Endpoint('client-app/3/', CLIENT_APPS_0[2]))

defaultEndpoints.push(new Endpoint('client-app/?organisation=de261f1b-e015-48bf-9af0-e8b67c0ab935',
CLIENT_APPS_1))
defaultEndpoints.push(new Endpoint('client-app/4/', CLIENT_APPS_1[0]))
*/

export class MockHandler {
  static instance: MockHandler;
  private mock: MockAdapter;

  private constructor(axios) {
    this.mock = new MockAdapter(axios, { delayResponse: 0 });
    this.registerMockEndpoints(defaultEndpoints);
    this.mock.onAny().passThrough();
  }

  static getInstance(axios?) {
    if (!MockHandler.instance) {
      if (axios !== undefined) {
        MockHandler.instance = new MockHandler(axios);
      } else {
        throw new Error('Need to pass axios when creating new MockHandler');
      }
    }
    return MockHandler.instance;
  }

  public registerMockEndpoints(endpoints: Endpoint[]) {
    // Register 'GET' and 'POST' for all endpoints
    endpoints.forEach(endpoint => {
      if (endpoint.isList) {
        this.mock.onGet(endpoint.url).reply(200, {
          results: endpoint.mockData,
        });

        this.mock.onPost(endpoint.url).reply(config => {
          const newObject = JSON.parse(config.data);
          addIdAndPermissions(newObject);
          endpoint.mockData.push(newObject);
          return [200, {}];
        });

        this.mock.onPatch(endpoint.url).reply(config => {
          const newObject = JSON.parse(config.data);
          endpoint.mockData.forEach(obj => {
            if (obj.id === newObject.id) {
              obj = newObject;
            }
          });
          return [200, newObject];
        });
      } else {
        this.mock.onGet(endpoint.url).reply(200, endpoint.mockData);
      }
    });
  }
}
