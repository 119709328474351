import deep from 'deep-diff';
import yaml from 'js-yaml';
import { Model, Classification, Product } from '@/models/device/models';
import {
  BaseObject,
  ListModelField,
  CellType,
  TransientBaseObject,
} from '@/models/core/base';
import {
  FIRMWARE_DEFAULT,
  CHANNEL_DEFAULT,
  DELIVERY_METHOD_DEFAULT,
  DELIVERY_PROCEDURE_DEFAULT,
  DELIVERY_ATTACHMENT_DEFAULT,
} from '@/models/firmware/defaults';
import { ApiClientV2, Annotation, RelatedAnnotation } from '@/api/ApiClientV2';
import { getObjectTypesUp } from '../objectRegistry';
import { objectFromMap, deepCopy } from '@/util/util';
import store from '@/store';

export class Channel extends BaseObject {
  id_text: string;
  name: string;
  model: string;
  classification: string;

  static apiUrl = 'channel';
  static objectType = 'channel';
  public static langPath = 'firmware.channel';
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: true,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }
  static listFields: ListModelField[] = [
    { key: 'name', sortable: true },
    { key: 'latest_firmware' },
    { key: 'id_text', cellType: CellType.CODE },
    {
      key: 'create_time',
      transform: createTime => {
        return new Date(createTime).toLocaleString();
      },
    },
    { key: 'classification_name' },
    {
      key: 'add_firmware',
      cellType: CellType.LINK,
      icon: 'plus',
      transform: () => 'Add',
      linkTo: row => {
        const ancestors = getObjectTypesUp('firmware');
        return {
          name: 'web-firmware-firmware-create', // firmwareRouteName('firmware-create'), TODO
          query: {
            ...objectFromMap(store.getters['global/idsFromContext'](ancestors)),
            channel: row.id,
          },
        };
      },
    },
    {
      key: 'view_firmware',
      cellType: CellType.LINK,
      icon: 'eye-outline',
      transform: () => 'View',
      linkTo: row => {
        const ancestors = getObjectTypesUp('firmware');
        return {
          name: 'web-firmware-firmware-list', // firmwareRouteName('firmware-list'), TODO
          query: {
            ...objectFromMap(store.getters['global/idsFromContext'](ancestors)),
            channel: row.id,
          },
        };
      },
    },
  ];
  static get joins(): RelatedAnnotation<Channel>[] {
    return [
      {
        relatedModelClass: Classification,
        relatedObjectProperty: 'name',
      },
    ];
  }
  static get annotations(): Annotation<Channel>[] {
    return [
      {
        key: 'latest_firmware',
        callback: async (channel: Channel, api: ApiClientV2) => {
          const response = await api.customGet('firmware/', {
            channel: channel.id,
            order_by: 'version_dsc',
            page: 1,
            pageSize: 1,
          });
          if (response.size >= 1) {
            return {
              id: channel.id,
              annotations: { latest_firmware: response.results[0].version },
            };
          } else {
            return {
              id: channel.id,
              annotations: { latest_firmware: '' },
            };
          }
        },
      },
    ];
  }
  static get defaultModel() {
    return deepCopy(CHANNEL_DEFAULT);
  }
  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }
}

export class DeliveryMethod extends BaseObject {
  model: string;
  name: string;
  description: string;

  static apiUrl = 'delivery-method';
  static langPath = 'firmware.deliveryMethod';
  static objectType = 'delivery-method';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'description',
    },
  ];

  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: true,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DELIVERY_METHOD_DEFAULT);
  }
}

export class DeliveryAttachment extends TransientBaseObject {
  id: string;
  create_time: string | Date;
  id_text: string;
  delivery_method: string;
  attachment: string;

  static apiUrl = 'delivery-attachment';
  static langPath = 'firmware.deliveryAttachment';
  static objectType = 'delivery-attachment';
  static listFields: ListModelField[] = [
    {
      key: 'id_text',
      cellType: CellType.CODE,
    },
    {
      key: 'create_time',
      transform: createTime => new Date(createTime).toLocaleString(),
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DELIVERY_ATTACHMENT_DEFAULT);
  }
}

export class DeliveryProcedurePlugin {
  plugin_id: string;
  config: any;
}

export class DeliveryProcedure extends BaseObject {
  name: string;
  comment: string;
  id_text: string;
  config_valid: boolean;
  device_options: {
    required: boolean;
    autotracking: boolean;
  };
  parameters: any;
  update_options: {
    expiration_policy: {
      ready: number;
      up_to_date: number;
      success?: number;
      fail?: number;
      unconfirmed?: number;
      version_strategy?: string;
    };
    auto_start: boolean;
    enable_confirm_step: boolean;
    confirm_timeout: number;
    concurrency_limit_retry_after: number;
    max_concurrent_pipelines: number;
  };
  delivery_method: string;
  stages: DeliveryProcedurePlugin[];
  config_text_buffer?: string;

  static apiUrl = 'delivery-procedure';
  static langPath = 'firmware.deliveryProcedure';
  static objectType = 'delivery-procedure';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'id_text',
      cellType: CellType.CODE,
    },
    {
      key: 'config_valid',
      cellType: CellType.TAG,
      transform: (config_valid: boolean): string => {
        return config_valid ? 'Valid' : 'Invalid';
      },
      class: (row: DeliveryProcedure) => {
        return row.config_valid ? 'is-success' : 'is-danger';
      },
    },
    {
      key: 'comment',
    },
  ];

  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: true,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
      },
      {
        modelClass: DeliveryMethod,
        requiredForFilter: true,
        mainParent: true,
      },
    ];
  }

  static get annotations(): Annotation<DeliveryProcedure>[] {
    return [
      {
        key: 'config_valid',
        callback: async (
          deliveryProcedure: DeliveryProcedure,
          api: ApiClientV2,
        ) => {
          const response = await api.get<DeliveryProcedure>(
            DeliveryProcedure,
            deliveryProcedure.id,
          );

          return {
            id: deliveryProcedure.id,
            annotations: { config_valid: response.config_valid as any },
          };
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DELIVERY_PROCEDURE_DEFAULT);
  }
}

export interface FirmwareImageMetaData {
  name: string;
  size: string;
  status: 'OK';
}

export class Firmware extends BaseObject {
  channel: string;
  version: string;
  publish_configuration: {
    publish_date?: Date;
  };
  publish_date?: Date | string;
  publish_state: string;
  img_file: string;
  img_hash: string;
  metadata?: any;
  disclaimer: string;
  release_note: string;

  static apiUrl = 'firmware';
  public static objectType = 'firmware';
  public static langPath = 'firmware.firmware';
  static listFields: ListModelField[] = [
    { key: 'version' },
    { key: 'channel_name' },
    { key: 'publish_configuration_date' },
    { key: 'publish_state' },
  ];
  static get ancestors() {
    return [
      {
        modelClass: Product,
        requiredForFilter: true,
      },
      {
        modelClass: Model,
        requiredForFilter: true,
      },
      {
        modelClass: Channel,
        requiredForFilter: false,
        mainParent: true,
      },
    ];
  }

  static get defaultModel() {
    return deepCopy(FIRMWARE_DEFAULT);
  }

  static get joins(): RelatedAnnotation<Firmware>[] {
    return [
      {
        relatedModelClass: Channel,
        relatedObjectProperty: 'name',
      },
    ];
  }
  static get annotations(): Annotation<Firmware>[] {
    return [
      {
        key: 'publish_configuration_date',
        callback: async (firmware: Firmware, api: ApiClientV2) => {
          try {
            let publish_configuration_date = '';
            if (firmware?.publish_configuration?.publish_date) {
              publish_configuration_date = new Date(
                firmware.publish_configuration.publish_date,
              ).toLocaleString();
            }
            return {
              id: firmware.id,
              annotations: {
                publish_configuration_date,
              },
            };
          } catch (err) {
            return {
              id: firmware.id,
              annotations: {
                publish_configuration_date: '',
              },
            };
          }
        },
      },
    ];
  }
}

export class StagesMaintainer {
  bufferStagesText: string;
  jsonObject: any;
  hasChanged = false;
  currentEditorVersion: number;

  constructor(_configBuffer: string, existingObj: any, editorVersion: number) {
    this.jsonObject = existingObj;
    // if config_buffer empty, config_buffer = YAML of json object
    if (
      _configBuffer === null ||
      _configBuffer === '' ||
      _configBuffer === undefined
    ) {
      this.bufferStagesText = this.json2yamlString(existingObj);
    } else {
      // otherwise check if JSON is same as JSON-ed buffer
      const jsonConfigBuffer = JSON.parse(_configBuffer);
      this.bufferStagesText = jsonConfigBuffer['stages_text'];
      const bufferJsonObject = yaml.load(this.bufferStagesText);
      console.debug('json object: ' + JSON.stringify(existingObj));
      console.debug('buffer as json: ' + JSON.stringify(bufferJsonObject));
      this.hasChanged =
        deep.diff(bufferJsonObject, this.jsonObject) !== undefined;
    }
  }

  updateBuffer() {
    this.bufferStagesText = this.json2yamlString(this.jsonObject);
  }

  private json2yamlString(jsonObject: any) {
    return yaml.dump(yaml.load(JSON.stringify(jsonObject)), {
      indent: 2,
      lineWidth: 150,
    });
  }
}

export interface Download extends BaseObject {
  firmware: string;
  delivery_procedure: string;
  firmware_version: string;
}

export const DELIVERY_PROCEDURE_STAGE_NAMES = {
  'builtin-download-local': 'Download Local',
  'builtin-encode-ihex32': 'Encode IHex32',
  'builtin-patch-firmware-image': 'Patch Firmware Image',
  'builtin-convert-payloads': 'Convert Payloads',
  'builtin-generate-leitwert-firmware-info': 'Generate Leitwert Firmware Info',
  'builtin-load-key': 'Load Key',
  'builtin-parse-ihex32': 'Parse IHex32',
  'builtin-load-const': 'Load Const',
  'builtin-load-attachment': 'Load Attachment',
  'builtin-encrypt-lwefwv2': 'Encrypt LWEFWV2',
  'builtin-generate-key': 'Generate Key',
};

export interface DurationType {
  durationSeconds: number;
  durationLabel: string;
}
