


























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceRelation, DataApplication } from '@/models/data/models';
import { Model } from '@/models/device/models';
import { ListModelField } from '@/models/core/base';
import { dataRouteName } from '@/apps/data/app';
import { globalStore } from '@/store/modules/global';

@Component({})
export default class DeviceRelationAssignList extends Vue {
  @Prop({ required: true }) appId: string;
  @Prop({ required: true }) role: string;
  @Prop({ required: true }) preSelectedRows: string[];
  @Prop({ required: true }) unselectableRows: string[];

  DeviceRelation = DeviceRelation;

  detailRouteName = dataRouteName('device-relation-detail');

  get filter() {
    return {
      application: this.appId,
      role: this.role,
      order_by: 'device_name_asc',
    };
  }

  get columns() {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
      },
      {
        key: 'device_device_id',
      },
    ];
    return DeviceRelation.defaultColumns(DeviceRelation.langPath, listFields);
  }

  get detailExtraParams() {
    return {
      app_handle:
        globalStore.selection.dataClientApp?.handle ||
        globalStore.selection.clientApp?.handle,
    };
  }
}
