// modules
import { ActionContext, Module } from 'vuex';

// common
import {
  apiClient,
  CollectionSubscriber,
  CollectionFilter,
  CollectionPagination,
} from '@/api/ApiClient';
import { apiClientV2 } from '@/api/ApiClientV2';
import store, { initialStateCopy } from './index';
import { ClientApp } from '@/models/client/models';
import { DataApplication, Stream } from '@/models/data/models';
import { isEmpty } from 'lodash';
import { Organisation } from '@/models/core/organisation';
import { Product, Model, Classification } from '@/models/device/models';
import { getStoreName, getModelClass } from '@/models/objectRegistry';
import { isMobile, deepCopy } from '@/util/util';
import {
  Firmware,
  Channel,
  DeliveryMethod,
  DeliveryProcedure,
} from '@/models/firmware/models';

export interface CommonContext {
  'selection': {
    'organisation': Organisation | null;
    'application': DataApplication | null;
    'stream': Stream | null;
    'client-app': ClientApp | null;

    'product': Product | null;
    'model': Model | null;
    'classification': Classification | null;

    'firmware': Firmware | null;
    'channel': Channel | null;
    'delivery-method': DeliveryMethod | null;
    'delivery-procedure': DeliveryProcedure | null;
  };

  'organisations': CollectionSubscriber;
  'applications': CollectionSubscriber;
  'streams': CollectionSubscriber;
  'client-apps': CollectionSubscriber;
  'groups': CollectionSubscriber;
  'roles': CollectionSubscriber;
  'object-authorizations': CollectionSubscriber;
  'event-logs': CollectionSubscriber;

  'products': CollectionSubscriber;
  'models': CollectionSubscriber;
  'classifications': CollectionSubscriber;
  'device-setting-kinds': CollectionSubscriber;
  'devices': CollectionSubscriber;

  'firmwares': CollectionSubscriber;
  'channels': CollectionSubscriber;
  'delivery-methods': CollectionSubscriber;
  'delivery-procedures': CollectionSubscriber;
}

const isMobileInit = isMobile();
document.body.classList.toggle('reader-mode', isMobileInit);

export class GlobalState {
  navigationIsActive = false; // TODO: -> move in navigation -> isActive
  navigation: {
    isCollapsed: boolean;
    selectionIsCollapsed: boolean;
    readerModeEnabled: boolean;
    navbarHidden: boolean;
    hideClientAppsMenu: boolean;
  } = {
    isCollapsed: isMobileInit,
    selectionIsCollapsed: isMobileInit,
    readerModeEnabled: isMobileInit,
    navbarHidden: false,
    hideClientAppsMenu: false,
  };
  isDebug = true;
  context: CommonContext = {
    'selection': {
      'organisation': null,
      'application': null,
      'stream': null,
      'client-app': null,
      'product': null,
      'model': null,
      'classification': null,
      'firmware': null,
      'channel': null,
      'delivery-method': null,
      'delivery-procedure': null,
    },
    'organisations': null,
    'applications': null,
    'streams': null,
    'client-apps': null,
    'groups': null,
    'roles': null,
    'object-authorizations': null,
    'event-logs': null,
    'products': null,
    'models': null,
    'classifications': null,
    'device-setting-kinds': null,
    'devices': null,
    'firmwares': null,
    'channels': null,
    'delivery-methods': null,
    'delivery-procedures': null,
  };
}

export class GlobalModule<R> implements Module<GlobalState, R> {
  namespaced = true;
  state = new GlobalState();

  getters = {
    'navigationIsCollapsed': state => state.navigation.isCollapsed,
    'selectionIsCollapsed': state => state.navigation.selectionIsCollapsed,
    'navbarHidden': state => state.navigation.navbarHidden,
    'readerModeEnabled': state => state.navigation.readerModeEnabled,
    'hideClientAppsMenu': state => state.navigation.hideClientAppsMenu,
    'navigationIsActive': state => state.navigationIsActive,
    'selectedClientApp': state => state.context.selection['client-app'],
    'contextOrganisations': state => state.context.organisations,

    // generic getters
    'objectByProperty': (state, getters, rootState) => (
      objectType: string,
      property: string,
      value: string,
    ) => {
      const storeModule = getStoreName(objectType);
      return (
        rootState[storeModule].context[`${objectType}s`] &&
        rootState[storeModule].context[`${objectType}s`].objects.find(
          obj => obj[property] === value,
        )
      );
    },

    'objectById': (state, getters, rootState) => (
      objectType: string,
      id: string,
    ) => {
      return getters.objectByProperty(objectType, id);
    },

    'object': (state, getters, rootState) => (objectType: string) => {
      const storeModule = getStoreName(objectType);
      return rootState[storeModule].context.selection[objectType];
    },

    /**
     * Generate a map containing all ids of given objectTypes
     * @param objectTypes List of objectTypes to process
     */
    'idsFromContext': (state, getters) => (
      objectTypes: string[],
    ): Map<string, string> => {
      const ids = new Map();
      for (const objectType of objectTypes) {
        const obj = getters.object(objectType);
        if (obj) {
          ids.set(objectType, obj.id);
        }
      }
      return ids;
    },

    'collection': (state, getters, rootState) => (objectType: string) => {
      const storeModule = getStoreName(objectType);
      return rootState[storeModule].context[`${objectType}s`];
    },

    'organisation': state => state.context.selection.organisation,
    'product': state => state.context.selection.product,
    'model': state => state.context.selection.model,
    'classification': state => state.context.selection.classification,
    'application': state => state.context.selection.application,

    'classifications': state => state.context.classifications,
    'device-setting-kinds': state => state.context['device-setting-kinds'],
    'devices': state => state.context.devices,
  };

  actions = {
    updateContextFilter(
      { commit, dispatch, rootState }: ActionContext<GlobalState, R>,
      {
        objectType,
        filter,
        pagination,
      }: {
        objectType: string;
        filter: CollectionFilter | null;
        pagination: CollectionPagination | null;
      },
    ): Promise<void> {
      const suffix = objectType.endsWith('s') ? 'es' : 's';
      const contextProperty = objectType + suffix;
      const storeName = getStoreName(objectType);
      const moduleState = rootState[storeName];
      let modelClass;
      try {
        modelClass = getModelClass(objectType);
      } catch (error) {
        // This is OK, it's an old model
      }

      if (pagination === undefined || isEmpty(pagination)) {
        if (modelClass) {
          pagination = modelClass.defaultPagination;
        }
      }

      // check if we have already set a subscriber. if not, create it
      if (moduleState.context[contextProperty] === undefined) {
        throw new Error(
          `Context property ${contextProperty} is not defined in the store. If it is an objectType, check the 'store' property in the object registry.`,
        );
      } else if (moduleState.context[contextProperty] === null) {
        let sub;
        if (modelClass && modelClass.useApiClientV2) {
          sub = apiClientV2.subscribe(null, modelClass, null, pagination);
        } else {
          sub = apiClient.subscribeList(objectType, null, pagination);
        }
        commit(
          `${storeName}/setContextSubscribers`,
          { [objectType]: sub },
          { root: true },
        );
      }
      return moduleState.context[contextProperty].status.readyPromise.then(
        () => {
          const filterChanged =
            JSON.stringify(moduleState.context[contextProperty].filter) !==
            JSON.stringify(filter);
          const paginationChanged =
            JSON.stringify(moduleState.context[contextProperty].pagination) !==
            JSON.stringify(pagination || {});
          if (filterChanged) {
            // console.log(contextProperty, 'filter changed:', filter)
            moduleState.context[contextProperty].filter = filter;
          }
          if (paginationChanged) {
            // console.log(contextProperty, 'pagination changed:', pagination)
            moduleState.context[contextProperty].pagination = pagination || {};
          }
          if (filterChanged || paginationChanged) {
            return moduleState.context[contextProperty]
              .refresh()
              .then((success: boolean) => {
                if (success) {
                  return;
                } else {
                  console.warn(`Could not update filter of ${contextProperty}`);
                }
              });
          } else {
            return;
          }
        },
      );
    },

    /**
     * get an object by objectType and id. if it is present in the
     * collection of the store, this item is returned, otherwise
     * the backend is queried through the api
     */
    getOrFetchObjectById(
      { commit, dispatch, state, getters }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id: string },
    ) {
      if (id === undefined) {
        return Promise.reject(
          new Error(
            `Value "undefined" is not allowed for "id" for objectType ${objectType}`,
          ),
        );
      }
      const objFromStore = getters.objectByProperty(objectType, 'id', id);
      if (objFromStore) {
        return Promise.resolve(objFromStore);
      } else {
        const modelClass = getModelClass(objectType);
        if (modelClass && modelClass.useApiClientV2) {
          return apiClientV2.get(modelClass, id);
        } else {
          return apiClient.get(objectType, id);
        }
      }
    },

    /**
     * set the context object by objectType and id
     */
    selectObjectById(
      { commit, dispatch, rootState }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id: string },
    ): Promise<void> {
      const storeName = getStoreName(objectType);
      const moduleState = rootState[storeName];

      if (id) {
        return dispatch('getOrFetchObjectById', { objectType, id })
          .then(object => {
            if (moduleState.context.selection[objectType] === undefined) {
              throw new Error(
                `Object type ${objectType} is not defined in the store. Check the 'store' property in the object registry.`,
              );
            } else if (
              moduleState.context.selection[objectType] === null ||
              moduleState.context.selection[objectType].id !== object.id
            ) {
              commit(
                `${storeName}/setContextSelection`,
                {
                  objectType,
                  object,
                },
                { root: true },
              );
            }
            return;
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              // This object does not exist, remove it from selection
              console.warn(
                `Object of type ${objectType} with id ${id} not exist`,
              );
              return dispatch('deselectObjectType', { objectType });
            } else {
              return Promise.reject(error);
            }
          });
      } else {
        return dispatch('deselectObjectType', { objectType });
      }
    },
    deselectObjectType(
      { commit, dispatch, rootState }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id?: string },
    ): void {
      const storeName = getStoreName(objectType);
      if (id !== undefined) {
        // Check if this id is currently in selection
        const moduleState = rootState[storeName];
        if (moduleState.context.selection[objectType]) {
          if (moduleState.context.selection[objectType].id !== id) {
            // This object is not currently in state, nothing to do
            return;
          }
        } else {
          return;
        }
      }
      commit(
        `${storeName}/setContextSelection`,
        {
          objectType,
          object: null,
        },
        { root: true },
      );
    },
    findObjectByProperty(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      {
        objectType,
        property,
        value,
      }: { objectType: string; property: string; value: string },
    ): Promise<Organisation> {
      const object = state.context[`${objectType}s`].objects.find(
        obj => obj[property] === value,
      );
      if (object) {
        return Promise.resolve(object);
      } else {
        return Promise.reject(
          new Error(
            `${objectType} with '${property} = ${value}' was not found`,
          ),
        );
      }
    },

    // find in object list in context, then set
    selectContextObjectByProperty(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      {
        objectType,
        property,
        value,
      }: { objectType: string; property: string; value: string },
    ): Promise<void> {
      if (value) {
        return dispatch('findObjectByProperty', {
          objectType,
          property,
          value,
        })
          .then(object => {
            if (
              state.context.selection[objectType] === null ||
              state.context.selection[objectType].id !== object.id
            ) {
              commit('setContextSelection', {
                objectType,
                object,
              });
            }
            return;
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              // This object does not exist, remove it from selection
              console.warn(
                `Object of type ${objectType} with property ${property} not exist`,
              );
              return dispatch('deselectObjectType', { objectType });
            } else {
              return Promise.reject(error);
            }
          });
      } else {
        return dispatch('deselectObjectType', { objectType });
      }
    },

    getFirstClientApp({
      commit,
      dispatch,
      state,
    }: ActionContext<GlobalState, R>) {
      const clientApp = state.context['client-apps'].objects[0];
      if (clientApp) {
        return Promise.resolve(clientApp);
      } else {
        return Promise.reject(new Error('Could not get first client app'));
      }
    },

    setNavigationCollapsed(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      { value }: { value: boolean },
    ): void {
      return commit('setNavigationCollapsed', {
        value,
      });
    },

    setSelectionCollapsed(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      { value }: { value: boolean },
    ): void {
      return commit('setSelectionCollapsed', {
        value,
      });
    },

    setReaderMode(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      { value }: { value: boolean },
    ): void {
      return commit('setReaderMode', {
        value,
      });
    },

    setNavbarHidden(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      { value }: { value: boolean },
    ): void {
      return commit('setNavbarHidden', {
        value,
      });
    },
  };

  mutations = {
    setNavigationIsActive(
      state: GlobalState,
      { value }: { value: boolean },
    ): void {
      state.navigationIsActive = value;
    },
    setNavbarHidden(state: GlobalState, { value }: { value: boolean }): void {
      state.navigation.navbarHidden = value;
    },
    setNavigationCollapsed(
      state: GlobalState,
      { value }: { value: boolean },
    ): void {
      state.navigation.isCollapsed = value;
    },
    setSelectionCollapsed(
      state: GlobalState,
      { value }: { value: boolean },
    ): void {
      state.navigation.selectionIsCollapsed = value;
    },
    setReaderMode(state: GlobalState, { value }: { value: boolean }): void {
      state.navigation.readerModeEnabled = value;
      document.body.classList.toggle('reader-mode', value);
      state.navigation.isCollapsed = true;
    },
    setHideClientAppsMenu(
      state: GlobalState,
      { value }: { value: boolean },
    ): void {
      document.body.classList.toggle('hide-client-apps', value);
      state.navigation.hideClientAppsMenu = value;
    },
    setContextSubscribers(
      state: GlobalState,
      subs: { [key: string]: CollectionSubscriber },
    ): void {
      Object.keys(subs).forEach(key => {
        state.context[key + 's'] = subs[key];
      });
    },
    setContextSelection(state: GlobalState, { objectType, object }): void {
      state.context.selection[objectType] = object;
    },
    clearContextSelection(state: GlobalState): void {
      // clear context selection except for organisation and client app
      const organisation = state.context.selection.organisation;
      const clientApp = state.context.selection['client-app'];
      state.context.selection = {
        ...deepCopy(initialStateCopy.global.context.selection),
        organisation,
        'client-app': clientApp,
      };
    },
    reset(state: GlobalState): void {
      store.state.global = deepCopy(initialStateCopy.global);
    },
  };
}
