import {
  ApiClientV2,
  Annotation,
  RelatedAnnotation,
  HookOption,
} from '@/api/ApiClientV2';
import {
  FormFieldType,
  FormField,
} from '@/components/common/forms/formBuilderHelper';
import { CollectionPagination } from '@/api/ApiClient';
import Vue from 'vue';
import { translate } from '@/lang/setup';
import { DEFAULT_ICON_MAP } from '@/models/core/icons';
import dotize from 'dotize';
import { get, unset } from 'lodash';
import { deepCopy, has } from '@/util/util';

export type ModelClass = typeof TransientBaseObject;

export interface Ancestor {
  modelClass: ModelClass;
  requiredForFilter?: boolean;
  mainParent?: boolean;
  relatedProperty?: string;
  showListFilter?: boolean;
}

export interface ListFilter {
  modelClass: ModelClass;
  property: string;
  key?: string;
}

export interface SearchFieldInterface {
  label: string;
  searchQuery: string;
}

export enum LifeCycleState {
  Draft = 'draft',
  PendingApproval = 'pending-approval',
  Approved = 'approved',
  PendingDeletion = 'pending-deletion',
  Deleted = 'deleted',
  Deactivated = 'deactivated',
}

export interface ListModelField extends ModelField {
  transform?: (fieldValue: any) => any | ((fieldValue: any) => any)[];
  class?: (
    row?: any,
    index?: number,
  ) => string | ((row?: any, index?: number) => string)[];
  linkTo?: (row?: any) => any;
  sortable?: boolean;
  sortFieldName?: string;
  searchQuery?: string;
  editProperties?: {
    type: CellEditType;
    min?: number;
    max?: number;
    step?: number;
    digits?: number;
    maxlength?: number;
    width?: number;
  };
}

export enum CellEditType {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
}

export enum CellType {
  BUTTON = 'button',
  LINK = 'link',
  CODE = 'code',
  TAG = 'tag',
  ICON = 'icon',
  EDITABLE_CELL = 'editable_cell',
  IMAGE = 'image',
  TEXT = 'text',
}

export interface BaseListColumn {
  label: string;
  fieldName: string;
  sortable?: boolean;
  sortFieldName?: string;
  cells: {
    type?: CellType;
    tooltip?: (row?: any, index?: number) => string;
    transform?: (fieldValue?: any) => string;
    class?: (row?: any) => string;
    linkTo?: (row?: any) => { name: string; query: any };
    icon?: string;
  }[];
}

export enum BaseListEventType {
  REORDER = 'reorder',
  DELETE = 'delete',
  UPDATE = 'update',
  CREATE = 'create',
}

export interface ModelField {
  key: string;
  formFieldType?: FormFieldType;
  required?: boolean;
  cellType?: CellType | CellType[];
  icon?: string;
  virtual?: boolean;
  undotized?: boolean; // Usually, a field like {a: {b: 2}} will be transformed to {a.b: 2}. If 'undotized' is set to true, this transformation is not done
  editable?: boolean; // TODO: Should this be removed?
  disabled?: boolean; // field always disabled
  tooltip?: (row: any) => any;
  placeholder?: string;
  customLabel?: string;

  formProperties?: {
    options?: { text: string; value: string }[];
    [key: string]: any;
  };

  display?: string; // condition whether to render field, e.g. 'false', or 'model.state === "active"'
  doNotRender?: boolean;
}

export interface FormConfig {
  fields: FormField[];
  model: { [key: string]: any };
}

export abstract class TransientBaseObject {
  id: string;
  create_time?: string | Date;
  creator?: string;
  object_state?: LifeCycleState;
  review_requester?: string;
  _permissions: {
    [s: string]: boolean;
  };

  /** Model Description **/
  // Type of the object as defined in backend
  static objectType: string = undefined;
  // Key used as foreign key in related objects
  static relatedObjectKey: string = undefined;
  // URL endpoint to this object type
  static apiUrl: string = undefined;
  // If true, there should no trailing slash be appended at the end of the url
  static noTrailingSlash = false;
  // The default view id (client app) of this object type
  static defaultViewId: string = undefined;
  // The default display property
  static displayProperty: string = undefined;
  // Ancestors
  static ancestors: Ancestor[] = [];
  // Annotations
  static annotations: Annotation<TransientBaseObject>[] = [];
  // Data joins
  static joins: RelatedAnnotation<TransientBaseObject>[] = [];
  // Defines if model has a life cycle and whether to show to user
  static hasLifeCycle = false;
  static showLifeCycle = false;
  // Path to language file
  static langPath: string = undefined;
  // Default model to be used for new objects
  static defaultModel: any = undefined;
  // Defines whether this model uses the new ApiClientV2
  static useApiClientV2 = false;

  /** List and Form Definitions **/
  static fields: ModelField[] = [];
  static listFields: ListModelField[] = [];
  static listFilters: ListFilter[] = [];
  static defaultPagination: CollectionPagination = null;

  static detailLinkQuery(context: { [key: string]: string }) {
    return {};
  }

  static formConfig(): FormConfig {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: {},
    };
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static prettyName(plural = false): string {
    return TransientBaseObject.translate(
      this.langPath,
      { key: 'prettyName' },
      plural,
    );
  }

  static translate(langPath: string, field: ModelField, plural = false) {
    return translate(`${langPath}.${field.key}`, plural);
  }

  /**
   * Returns icon for a field
   * @param field field name (e.g. 'description', 'meta.phone', ..)
   * @param customIcons custom icons that each model can define
   */
  static getIcon(field: ModelField) {
    if (field.icon !== undefined) {
      return field.icon;
    } else {
      // For nested fieldnames like 'meta.phone', only look at last part (e.g. 'phone')
      const fieldName = field.key.split('.')[field.key.split('.').length - 1];

      // Check if field name is in default icons, otherwise return default
      return DEFAULT_ICON_MAP[fieldName]
        ? DEFAULT_ICON_MAP[fieldName]
        : 'mdi-text';
    }
  }

  static getFormFieldLabel(langPath: string, field: ModelField) {
    return `${langPath}.fields.${field.key}`;
  }

  static getPlaceholder(langPath: string, field: ModelField) {
    return `${langPath}.fields.${field.key}`;
  }

  static defaultFormFields(
    langPath: string,
    fields: ModelField[],
  ): FormField[] {
    const formFields: FormField[] = [];
    fields.forEach(field => {
      if (!field.doNotRender) {
        formFields.push({
          key: field.key,
          type:
            field.formFieldType !== undefined
              ? field.formFieldType
              : FormFieldType.INPUT,
          required: field.required !== undefined ? field.required : true,
          properties: {
            label:
              field.customLabel ||
              translate(this.getFormFieldLabel(langPath, field)),
            placeholder:
              field.placeholder ??
              translate(this.getPlaceholder(langPath, field)),
            icon: this.getIcon(field),
            ...field.formProperties,
          },
          display: field.display,
        });
      }
    });
    return formFields;
  }

  static defaultColumns(
    langPath: string,
    fields: ListModelField[],
  ): BaseListColumn[] {
    const columns: BaseListColumn[] = [];
    fields.forEach(field => {
      const cells = [];
      // field has multiple cells
      if (field.cellType && Array.isArray(field.cellType)) {
        for (let i = 0; i < field.cellType.length; i++) {
          cells.push({
            type: (field.cellType && field.cellType[i]) || CellType.TEXT,
            transform: (field.transform && field.transform[i]) || undefined,
            tooltip: (field.tooltip && field.tooltip[i]) || undefined,
            class: (field.class && field.class[i]) || undefined,
            linkTo: (field.linkTo && field.linkTo[i]) || undefined,
            icon: field.icon,
          });
        }
      }
      // field has only one cell
      else {
        cells.push({
          type: field.cellType || CellType.TEXT,
          transform: field.transform,
          tooltip: field.tooltip,
          class: field.class,
          linkTo: field.linkTo,
          icon: field.icon,
          editProperties: field.editProperties,
        });
      }

      columns.push({
        label:
          field.customLabel ||
          translate(this.getFormFieldLabel(langPath, field)),
        fieldName: field.key,
        sortable: !!field.sortable,
        sortFieldName: field.sortFieldName,
        cells: cells,
      });
    });

    return columns;
  }

  static parseModel(model: any, fields: ModelField[]) {
    const undotized = {};
    const model_copy = deepCopy(model);
    fields.forEach((field: ModelField) => {
      if (field.undotized && get(model_copy, field.key)) {
        undotized[field.key] = get(model_copy, field.key);
        unset(model_copy, field.key);
      }
    });
    const out = dotize.convert(model_copy);
    Object.keys(undotized).forEach(key => {
      out[key] = undotized[key];
    });
    return out;
  }

  static searchFields(
    langPath: string = this.langPath,
    fields: ListModelField[] = this.listFields,
  ): SearchFieldInterface[] {
    const searchFields: SearchFieldInterface[] = [];
    fields.forEach(field => {
      if (field.searchQuery && field.searchQuery.length > 0) {
        searchFields.push({
          label:
            field.customLabel ||
            translate(this.getFormFieldLabel(langPath, field)),
          searchQuery: field.searchQuery,
        });
      }
    });
    return searchFields;
  }

  static isValidFilter(filter): boolean {
    // Check if all required parameters are fulfilled
    const ancestors = this.ancestors || [];
    for (const ancestor of ancestors) {
      const objectType = ancestor.modelClass.objectType.replace('-', '_');
      if (
        // if ancestor is required AND
        //    it is not in the filter OR
        //    it is in the filter but undefined OR empty
        ancestor.requiredForFilter &&
        (!has(filter, objectType) ||
          (has(filter, objectType) &&
            (filter[objectType] === undefined || filter[objectType] === '')))
      ) {
        // the id of a required filter parameter is not given,
        // e.g. model_id not given for classification filter
        // hence filter is not valid
        return false;
      }
    }
    return true;
  }

  /**
   * Function to be called before saving object
   */
  static beforeSaveHook: (
    apiClient: ApiClientV2,
    obj: TransientBaseObject,
    hookOptions?: HookOption,
  ) => Promise<any> = undefined;

  /**
   * Function to be called after saving object
   */
  static afterSaveHook: (
    apiClient: ApiClientV2,
    obj: TransientBaseObject,
    hookOptions?: HookOption,
    responseData?: any,
  ) => Promise<any> = undefined;

  /**
   * Returns a custom URL for creating object (without '/api/v1')
   */
  static customCreateUrl: (obj: TransientBaseObject) => string = undefined;

  /**
   * Returns a custom URL for updating object (without '/api/v1')
   */
  static customUpdateUrl: (obj: TransientBaseObject) => string = undefined;

  /**
   * Function to transfrom an object for a JsonField in BaseFormV2
   */
  static transformJsonObject: (obj: any) => any = undefined;

  static insertBefore: (
    vm: Vue,
    insert: TransientBaseObject,
    before: TransientBaseObject,
  ) => Promise<void> = undefined;
}

export class BaseObject extends TransientBaseObject {
  object_state: LifeCycleState;

  public static annotations: Annotation<BaseObject>[] = [];
  public static joins: RelatedAnnotation<BaseObject>[] = [];
  public static hasLifeCycle = true;
  public static showLifeCycle = true;
}

export const TRANSIENT_BASE_OBJECT_DEFAULT: TransientBaseObject = {
  id: '0',
  _permissions: {},
};

export const BASE_OBJECT_DEFAULT: BaseObject = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  object_state: LifeCycleState.Draft,
};
