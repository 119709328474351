

































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { DEVICE_DEFAULT } from '@/models/device/defaults';
import { Device, DeviceDetails } from '@/models/device/models';
import ParentSelectors from '@/components/common/ParentSelectors.vue';
import { deepCopy } from '@/util/util';

import { deviceRouteName } from '../app';

@Component({
  components: {
    BaseForm,
    ParentSelectors,
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeviceForm extends Vue {
  @Prop({ required: true }) id: string;

  $refs: {
    baseForm: BaseForm;
  };
  simpleMode = true;
  device: Device = deepCopy(DEVICE_DEFAULT);
  loading = false;
  deviceIds = '';
  deviceDetails: DeviceDetails[] = [{ name: '', device_id: '' }];
  deviceDetailsPlaceholder: DeviceDetails[] = [{ name: '', device_id: '' }];

  deviceRouteName = deviceRouteName;

  onCreateFinished(response) {
    const loadingComponent = this.$buefy.loading.open({});
    this.$bgTaskHandler.watchTask(
      response.data,
      () => {
        if (this.$store.getters['global/collection']('device')) {
          this.$store.getters['global/collection']('device').refresh();
        }
        this.$apiv2.refreshData();
        loadingComponent.close();
      },
      error => {
        this.$buefy.toast.open({
          message: error,
          type: 'is-danger',
        });
        loadingComponent.close();
      },
    );
  }

  addDeviceDetail() {
    this.deviceDetailsPlaceholder.push({ name: '', device_id: '' });
  }

  removeDeviceDetail(index) {
    this.deviceDetailsPlaceholder.splice(index, 1);
  }

  @Watch('deviceDetailsPlaceholder', { deep: true })
  deviceDetailsChange() {
    this.deviceDetails = [];
    for (let i = 0; i < this.deviceDetailsPlaceholder.length; ++i) {
      const deviceDetail: DeviceDetails = this.deviceDetailsPlaceholder[i];
      if (deviceDetail.device_id !== '') {
        this.deviceDetails.push({
          name: deviceDetail.name,
          device_id: deviceDetail.device_id,
        });
      }
    }
  }

  bulkCreateDevices(dataObject) {
    return this.$api.customCreate('device/bulk-create', dataObject);
  }

  get transformedDevice() {
    if (this.simpleMode) {
      const lines = this.deviceIds.split('\n');
      const ids = [];
      lines.forEach(line => {
        const lineIds = line.split(',');
        lineIds.forEach(id => {
          ids.push(id.trim());
        });
      });
      ids.filter(id => {
        return id !== '';
      });
      this.device.model = this.$store.getters['global/object']('model')?.id;
      this.device.devices = ids;
    } else {
      this.device.model = this.$store.getters['global/object']('model')?.id;
      this.device.devices = deepCopy(this.deviceDetails);
    }

    this.device.classification = this.$store.getters['global/object'](
      'classification',
    )?.id;

    const transDevice: Device = deepCopy(this.device);
    if (transDevice.classification === '') {
      delete transDevice.classification;
    }
    return transDevice;
  }
}
