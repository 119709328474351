import { Product } from '@/models/device/models';
import { ORGANISATIONS } from './organisations';
import { LifeCycleState } from '@/models/core/base';

export const PRODUCTS: Product[] = [
  {
    id: 'product_a',
    object_state: LifeCycleState.Approved,
    name: 'Product A',
    organisation: ORGANISATIONS[0].id,
    _permissions: {
      edit: true,
      view: true,
    },
  },
  {
    id: 'product_b',
    object_state: LifeCycleState.Approved,
    name: 'Product B',
    organisation: ORGANISATIONS[0].id,
    _permissions: {
      edit: true,
      view: true,
    },
  },
  {
    id: 'product_c',
    object_state: LifeCycleState.Approved,
    name: 'Product C',
    organisation: ORGANISATIONS[0].id,
    _permissions: {
      edit: true,
      view: true,
    },
  },
];
