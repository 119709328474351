import Vue from 'vue';
import * as Sentry from '@sentry/vue';

export function initializeSentry(
  dsn: string,
  environment: string,
  release: string,
): void {
  const enableSentryDebug = environment === 'dev' || environment === 'staging';
  enableSentryDebug &&
    console.info(`Enabling Sentry debug in environment '${environment}'`);
  Sentry.init({
    dsn: dsn,
    environment: environment,
    release: release,
    debug: enableSentryDebug,
    Vue: Vue,
  });
}
