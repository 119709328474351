import { updateStateForRoute } from '../routingUtils';
import { clientAppRegistryGet } from '../clientAppRegistry';
import { VIEW_ID, firmwareRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';
import { Location, RouteConfig } from 'vue-router';

import MainMenu from '@/apps/firmware/components/MainMenu.vue';

import ChannelList from '@/apps/firmware/components/ChannelList.vue';
import ChannelForm from '@/apps/firmware/components/ChannelForm.vue';

import DeliveryMethodList from '@/apps/firmware/components/DeliveryMethodList.vue';
import DeliveryMethodForm from '@/apps/firmware/components/DeliveryMethodForm.vue';

import DeliveryProcedureList from '@/apps/firmware/components/DeliveryProcedureList.vue';
import DeliveryProcedureForm from '@/apps/firmware/components/DeliveryProcedureForm.vue';

import FirmwareList from '@/apps/firmware/components/FirmwareList.vue';
import FirmwareForm from '@/apps/firmware/components/FirmwareForm.vue';

async function beforeEnterFirmwareApp(to): Promise<void | Location> {
  const objectList = [
    'product',
    'model',
    'classification',
    'channel',
    'delivery-method',
    'delivery-procedure',
    'firmware',
  ];

  return updateStateForRoute(to, objectList);
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: firmwareRouteName('channel-root') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterFirmwareApp,
  },
  children: [
    {
      path: 'channel',
      name: firmwareRouteName('channel-root'),
      redirect: { name: firmwareRouteName('channel-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: firmwareRouteName('channel-list'),
          props: true,
          component: ChannelList,
          meta: {
            requiresList: {
              channel: true,
            },
            hasQuery: {
              product: true,
              model: true,
            },
            hasPagination: {
              objects: 'channel',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'channel',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: firmwareRouteName('channel-create'),
          props: { id: '0' },
          component: ChannelForm,
          meta: {
            hasQuery: {
              product: true,
              model: true,
              classification: true,
            },
          },
        },
        {
          path: ':id',
          name: firmwareRouteName('channel-detail'),
          props: true,
          component: ChannelForm,
          meta: {
            detailOf: 'channel',
          },
        },
      ],
    },

    {
      path: 'delivery-method',
      name: firmwareRouteName('delivery-method-root'),
      redirect: { name: firmwareRouteName('delivery-method-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: firmwareRouteName('delivery-method-list'),
          props: true,
          component: DeliveryMethodList,
          meta: {
            requiresList: {
              'delivery-method': true,
            },
            hasQuery: {
              product: true,
              model: true,
            },
            hasPagination: {
              objects: 'delivery-method',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'delivery-method',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: firmwareRouteName('delivery-method-create'),
          props: { id: '0' },
          component: DeliveryMethodForm,
          meta: {
            hasQuery: {
              product: true,
              model: true,
              classification: true,
            },
          },
        },
        {
          path: ':id',
          name: firmwareRouteName('delivery-method-detail'),
          props: true,
          component: DeliveryMethodForm,
          meta: {
            detailOf: 'delivery-method',
            requiresList: {
              'delivery-procedure': true,
            },
          },
        },
      ],
    },

    {
      path: 'delivery-procedure',
      name: firmwareRouteName('delivery-procedure-root'),
      redirect: { name: firmwareRouteName('delivery-procedure-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: firmwareRouteName('delivery-procedure-list'),
          props: true,
          component: DeliveryProcedureList,
          meta: {
            requiresList: {
              'delivery-procedure': true,
            },
            hasQuery: {
              'product': true,
              'model': true,
              'delivery-method': true,
            },
            hasPagination: {
              objects: 'delivery-procedure',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'delivery-procedure',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: firmwareRouteName('delivery-procedure-create'),
          props: { id: '0' },
          component: DeliveryProcedureForm,
          meta: {
            hasQuery: {
              'product': true,
              'model': true,
              'delivery-method': true,
            },
          },
        },
        {
          path: ':id',
          name: firmwareRouteName('delivery-procedure-detail'),
          props: true,
          component: DeliveryProcedureForm,
          meta: {
            detailOf: 'delivery-procedure',
          },
        },
      ],
    },

    {
      path: 'firmware',
      name: firmwareRouteName('firmware-root'),
      redirect: { name: firmwareRouteName('firmware-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: firmwareRouteName('firmware-list'),
          props: true,
          component: FirmwareList,
          meta: {
            hasQuery: {
              product: true,
              model: true,
              channel: true,
            },
            hasPagination: {
              objects: 'firmware',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
          },
        },
        {
          path: 'create',
          name: firmwareRouteName('firmware-create'),
          props: { id: '0' },
          component: FirmwareForm,
          meta: {
            hasQuery: {
              product: true,
              model: true,
              channel: true,
            },
          },
        },
        {
          path: ':id',
          name: firmwareRouteName('firmware-detail'),
          props: true,
          component: FirmwareForm,
          meta: {
            detailOf: 'firmware',
          },
        },
      ],
    },
  ],
};

export default route;
