






























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DEVICE_DEFAULT } from '@/models/device/defaults';
import {
  DATA_APPLICATION_DEFAULT,
  DATA_SOURCE_PARAMS_DEFAULT,
  DATA_SOURCE_TEMPLATE_DEFAULT,
} from '@/models/data/defaults';
import { Device } from '@/models/device/models';
import {
  DataApplication,
  DataSourceOutputType,
  DataSourceTemplate,
  DeviceRelation,
} from '@/models/data/models';
import moment from 'moment';
import DataSourceHandler from '@/apps/data/components/explorer/DataSourceHandler.vue';
import { Context } from '@/api/ApiClientV2';

@Component({
  components: {
    DataSourceHandler,
  },
})
export default class DeviceDataExplorer extends Vue {
  @Prop({ required: true }) device: string;
  @Prop({ required: true }) appId: string;
  @Prop({ required: true }) options: { template: string };

  $refs: {
    dataSourceHandler: DataSourceHandler;
  };
  application: DataApplication;
  errorMessages: string[] = [];
  startDate: Date = new Date();
  deviceObject: Device = null;

  @Watch('$route.query')
  onRouteChanged() {
    this.load();
  }

  async mounted() {
    await this.load();
  }

  async load() {
    const loading = this.$buefy.loading.open({});
    try {
      const dateString = this.$routerHandler.query('')['date'];
      if (dateString) {
        this.startDate = new Date(dateString);
      }
      this.deviceObject = await this.$apiv2.get<Device>(Device, this.device);
      const template = await this.$apiv2.get<DataSourceTemplate>(
        DataSourceTemplate,
        this.options.template,
      );
      this.$refs.dataSourceHandler.addDataSourceTemplate({
        template: template.id,
        parameters: this.parameters,
        output_type: DataSourceOutputType.TIME_SERIES,
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  get parameters() {
    const startDate = moment(this.startDate);
    const startT = startDate.startOf('day').toISOString();
    const endT = startDate.add(1, 'days').startOf('day').toISOString();
    return {
      start_time: startT,
      end_time: endT,
      device: this.device,
    };
  }

  paramsChanged() {
    this.$routerHandler.updateQuery('', { date: this.startDate.toISOString() });
    // Prepare data source request
    const parameters = this.parameters;
    this.$refs.dataSourceHandler.setTemplateParameters(parameters);
  }
}
