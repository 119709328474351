export class ActivityTrackerAccelerometerSettings {
  odr: number;
  range: number;
  half_bw: number;
  low_noise: number;
}

export const ACT_TRACKER_ACC_SETTINGS_DEFAULT: ActivityTrackerAccelerometerSettings = {
  odr: 1,
  range: 1,
  half_bw: 1,
  low_noise: 0,
};

export const OdrOptions = [
  {
    value: 0,
    name: '12.5 Hz',
  },
  {
    value: 1,
    name: '25 Hz',
  },
  {
    value: 2,
    name: '50 Hz',
  },
  {
    value: 3,
    name: '100 Hz',
  },
  {
    value: 4,
    name: '200 Hz',
  },
  {
    value: 5,
    name: '400 Hz',
  },
];

export const RangeOptions = [
  {
    value: 0,
    name: '±2 g',
  },
  {
    value: 1,
    name: '±4 g',
  },
  {
    value: 2,
    name: '±8 g',
  },
];

export const HalfBwOptions = [
  {
    value: 0,
    name: 'Bandwidth is ODR / 2',
  },
  {
    value: 1,
    name: 'Bandwidth is ODR / 4',
  },
];

export const LowNoiseOptions = [
  {
    value: 0,
    name: 'Normal Operation (lowest power)',
  },
  {
    value: 1,
    name: 'Low Noise Mode',
  },
  {
    value: 2,
    name: 'Ultra Low Noise Mode',
  },
];

export interface ActivityTrackerReadoutProgress {
  valid: boolean;
  reason: string | null;
  inProgress: boolean;
  value: number | null;
  sensorIds: string[] | null;
}

/**
 * Determines the readout progress by looking at the device state.
 * @param state The device state to parse.
 */
export function determineReadoutProgress(
  state: any,
): ActivityTrackerReadoutProgress | Promise<ActivityTrackerReadoutProgress> {
  try {
    if (typeof state.readout_state === 'object' && state.readout_state) {
      switch (state.readout_state.version) {
        case 1:
          return {
            valid: true,
            reason: null,
            inProgress: true,
            value: Math.min(
              1,
              ...state.readout_state.states.map(s => {
                if (s.top !== s.end) {
                  return Math.max(
                    0,
                    Math.min(1, (s.top - s.current) / (s.top - s.end)),
                  );
                } else {
                  return 1;
                }
              }),
            ),
            sensorIds: state.readout_state.states.map(s => s.sensor_id),
          };
        default:
          return {
            valid: false,
            reason: 'state version not supported',
            inProgress: false,
            value: null,
            sensorIds: null,
          };
      }
    }
    if (
      typeof state.last_position_markers === 'object' &&
      state.last_position_markers
    ) {
      return {
        valid: true,
        reason: null,
        inProgress: false,
        value: null,
        sensorIds: Object.keys(state.last_position_markers),
      };
    } else {
      return {
        valid: false,
        reason: 'No data in device state',
        inProgress: false,
        value: null,
        sensorIds: null,
      };
    }
  } catch (err) {
    return {
      valid: false,
      reason: err.toString(),
      inProgress: false,
      value: null,
      sensorIds: null,
    };
  }
}
