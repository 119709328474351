

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { BaseListColumn, ListModelField } from '@/models/core/base';
import { Context, Filter } from '@/api/ApiClientV2';

@Component({})
export default class DeviceSelectionList extends Vue {
  @Prop({ required: true }) selectedDevices!: string[];
  @Prop({ required: true }) model!: string;

  Device = Device;

  get columns(): BaseListColumn[] {
    const listFields: ListModelField[] = [
      {
        key: 'name',
      },
      {
        key: 'device_id',
      },
      {
        key: 'model_name',
      },
    ];
    return Device.defaultColumns(Device.langPath, listFields);
  }

  get collectionFilter(): Filter {
    return { model: this.model, product: null };
  }

  async selectAll(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const context: Context = {
        filter: this.collectionFilter,
        pagination: {
          page: 1,
          pageSize: 500,
        },
      };
      const response = await this.$apiv2.getList<Device>(Device, context);
      if (response.size === 500) {
        this.$buefy.toast.open({
          message: 'Only first 500 selected',
          type: 'is-warning',
        });
      }
      const selection = response.results.map(d => d.id);
      this.$emit('row-selection', selection);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
