



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class MultilineTooltip extends Vue {
  @Prop({ required: false }) label: string;
  @Prop({ required: false }) type: string;
  @Prop({ required: false }) active: boolean;
  @Prop({ required: false }) position: string;
}
