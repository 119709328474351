

















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { DataApplication } from '@/models/data/models';
import { DATA_APPLICATION_DEFAULT } from '@/models/data/defaults';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import DeviceRelationAssignList from './DeviceRelationAssignList.vue';
import {
  getEverionAssignments,
  getDeviceSessionConfig,
  addEverions,
} from '@/apps/data/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deepCopy } from '@/util/util';
import { DEVICE_DEFAULT } from '@/models/device/defaults';

@Component({
  components: {
    DeviceRelationAssignList,
  },
  mixins: [BeforeLeaveGuard],
})
export default class GatewayEverionAssignmentView extends Vue {
  @Prop({ required: true }) device: string;
  @Prop({ required: true }) appId: string;

  gateway: Device = deepCopy(DEVICE_DEFAULT);
  application: DataApplication = deepCopy(DATA_APPLICATION_DEFAULT);
  loading = true;
  // everions assigned to this gateway
  selectedRelations: string[] = [];
  // everions assigned to other gateways
  assignedRelations: string[] = [];
  warningMessages: string[] = [];

  destroySubject = new Subject<void>();

  async mounted() {
    await this.load();
    this.loading = false;
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.gateway = await this.$apiv2.get<Device>(Device, this.device);
      this.application = await this.$apiv2.get<DataApplication>(
        DataApplication,
        this.appId,
      );
      await this.getEverionAssignments();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  async getEverionAssignments() {
    const assignments = await getEverionAssignments(
      this.$apiv2,
      this.appId,
      this.device,
    );
    this.selectedRelations = assignments.selected.filter(rel => !!rel);
    this.assignedRelations = assignments.assigned.filter(rel => !!rel);
  }

  setSelectedRelations(devices: string[]) {
    this.selectedRelations = devices;
  }

  async updateEverionSettings() {
    const clientAppId: string = this.$store.getters['global/selectedClientApp']
      .id;
    const deviceSessionConfig = await getDeviceSessionConfig(
      this.$apiv2,
      clientAppId,
      'everion',
    );
    if (!deviceSessionConfig) {
      throw new Error(
        '"device_session_config" missing in client app settings: device_models > role: everion',
      );
    }
    await addEverions(
      this.$apiv2,
      this.appId,
      this.device,
      this.selectedRelations.filter(rel => !!rel),
      deviceSessionConfig,
    );
  }

  async save() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      await this.updateEverionSettings();
      await this.$apiv2.refreshData();
    } catch (error) {
      this.$errorHandler.handleError(error);
      await this.$apiv2.refreshData();
    }
    loadingComponent.close();
  }
}
