



















































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  Pagination,
  Filter,
  Annotation,
  RelatedAnnotation,
} from '@/api/ApiClientV2';
import {
  BaseListColumn,
  ModelClass,
  TransientBaseObject,
} from '@/models/core/base';
import BaseListTable from './BaseListTable.vue';
import ParentSelectorsV2 from '@/components/common/lists/ParentSelectorsV2.vue';
import SearchFields from '@/components/common/lists/SearchFields.vue';
import ListPagination from './ListPagination.vue';
import { getClientAppOfRoute } from '@/apps/routingUtils';
import { clientAppRouteName } from '@/apps/clientAppRegistry';
import { objectFromMap, has } from '@/util/util';
import { getObjectTypesUp } from '@/models/objectRegistry';
import { CustomAction } from './interfaces';
import { RawLocation } from 'vue-router';
import { ApiListSubscription } from '@/api/ApiListSubscription';

@Component({
  components: {
    ParentSelectorsV2,
    SearchFields,
    ListPagination,
    BaseListTable,
  },
})
/**
 * The BaseListV2 component is used to render lists of objects of a certain model class fetched from the back-end.
 */
export default class BaseListV2<T> extends Vue {
  @Prop({ required: true }) modelClass!: ModelClass;
  @Prop({ default: () => [] }) customActions!: CustomAction[];
  @Prop({ default: true }) hasActions!: boolean;
  @Prop({ default: false }) canCopy!: boolean;
  @Prop({ default: true }) canAdd!: boolean;
  @Prop({ default: true }) canDelete!: boolean;
  @Prop({ default: false }) canSelect!: boolean;
  @Prop({ default: false }) canReorder!: boolean;
  @Prop({ default: true }) canCopyData!: boolean;
  @Prop({ default: false }) singleSelection!: boolean;
  @Prop({ default: () => [] }) preSelectedRows!: string[];
  @Prop({ default: () => [] }) addSelectedRows!: string[];
  @Prop({ default: () => [] }) unselectableRows!: string[];
  @Prop({ default: true }) hasDetailView!: boolean;
  @Prop({ default: () => {} }) detailExtraParams!: any;
  @Prop({ default: null }) createRouteExtras: any;
  @Prop({ default: '' }) detailRouteName!: string;
  @Prop({ default: '' }) createRouteName!: string;
  @Prop({ default: null }) customDelete!: (data: any) => Promise<any> | null;
  @Prop({ default: null }) customDetail!: (data: any) => RawLocation | null;
  @Prop({ default: null }) customColumns!: BaseListColumn[] | null;
  @Prop({ default: null }) collectionFilter!: Filter | null;
  @Prop({ default: '' }) filterPrefix!: string;
  @Prop({ default: '' }) helpMessage!: string;
  @Prop({ default: false }) isStriped!: boolean;
  @Prop({ default: true }) showFilters!: boolean;
  @Prop({ default: true }) showParentSelectors!: boolean;
  @Prop({ required: false }) parentSelectorFilter!: { [key: string]: string };

  columns: BaseListColumn[] = this.customColumns || this.modelClass.columns();
  columnsClone: BaseListColumn[] =
    this.customColumns || this.modelClass.columns();
  objectType: string = this.modelClass.objectType;
  errorMessages: string[] = [];
  error = false;

  objectCollection: ApiListSubscription<T> = this.$apiv2.subscribe<any, T>(
    this,
    this.modelClass,
    null,
    {
      page: 1,
      pageSize: 10,
    },
    this.annotations,
    this.joins,
  );

  get annotations(): Annotation<TransientBaseObject>[] {
    // only resolve annotions that are actually in the columns
    return this.modelClass.annotations.filter(a =>
      this.columns.find(c => c.fieldName === a.key),
    );
  }

  get joins(): RelatedAnnotation<TransientBaseObject>[] {
    return this.modelClass.joins;
  }

  created() {
    this.beforeMountCheck();
    this.filterCollection();
  }

  pagination(): Pagination {
    return this.$routerHandler.pagination(this.prefix);
  }

  filter() {
    return this.$routerHandler.query(this.prefix);
  }

  async refreshCollection() {
    await this.objectCollection.refresh();
  }

  @Watch('$route.query')
  filterCollection() {
    const newFilter = this.filter();
    const newPagination = this.pagination();

    let combinedFilter: Filter | null = {
      ...this.collectionFilter,
      ...newFilter,
    };

    if (!this.modelClass.isValidFilter(combinedFilter)) {
      // the id of a required filter parameter is not given,
      // e.g. model_id not given for classification filter
      // hence set filter to null
      combinedFilter = null;
    }
    this.objectCollection.setFilterAndPagination(combinedFilter, newPagination);
  }

  get prefix() {
    return this.filterPrefix !== '' ? `${this.filterPrefix}~` : '';
  }

  @Watch('collectionFilter', { deep: true })
  onFilter() {
    this.$routerHandler.updateQuery(this.prefix, {
      ...this.collectionFilter,
    });
  }

  /**
   * This function should check that the modelClass is properly configured with all required fields
   * so that the BaseList can work
   */
  beforeMountCheck() {
    const errors = [];
    if (!this.modelClass) {
      errors.push(`BaseListV2: The object class has not been defined.`);
    }
    if (!this.objectType) {
      // If the 'objectType' is not defined on the class, we can try and find the class name
      const className = this.modelClass.constructor.name;
      errors.push(
        `BaseListV2: The object class ${className} has not defined its 'objectType'`,
      );
    }
    if (this.objectType && !this.columns) {
      errors.push(
        `BaseList2: The object class '${this.objectType}' has not defined any columns!`,
      );
    }
    if (this.objectType && !this.objectCollection) {
      errors.push(
        `BaseList2: The objectCollection for type '${this.objectType}' is null/undefined!`,
      );
    }
    if (errors.length > 0) {
      this.$router.replace({
        name: 'error',
        params: {
          errors: JSON.stringify(errors),
        },
      });
    }
  }

  onPageChange(page: number) {
    this.$routerHandler.setPage(this.prefix, page);
  }

  onSort(field: string, order: string) {
    let sortField = field;
    this.columnsClone.forEach((column: BaseListColumn) => {
      if (column.fieldName === field) {
        if (column.sortFieldName) {
          sortField = column.sortFieldName;
        }
      }
    });

    this.$routerHandler.updateQuery(this.prefix, {
      order_by: sortField + (order === 'desc' ? '_dsc' : ''),
    });
  }

  getCreateRouteName() {
    if (this.createRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route);
      return clientApp
        ? clientAppRouteName(clientApp.view_id, this.objectType + '-create')
        : '';
    }
    return this.createRouteName;
  }

  addNew() {
    let createRouteExtras;
    if (this.createRouteExtras === null) {
      // no explicit create route extras given. generate query ids from context
      // use ancestors objectTypes
      let ancestors = getObjectTypesUp(this.objectType);
      createRouteExtras = {
        query: objectFromMap(
          this.$store.getters['global/idsFromContext'](ancestors),
        ),
      };
      ancestors = [
        ...this.modelClass.ancestors.map(
          ancestor => ancestor.modelClass.objectType,
        ),
        ...ancestors,
      ];
      // remove duplicates
      ancestors = [...new Set(ancestors)];

      for (const ancestor of ancestors) {
        if (has(this.$route.query, ancestor)) {
          createRouteExtras.query = {
            ...createRouteExtras.query,
            [ancestor]: this.$route.query[ancestor],
          };
        }
      }
    } else {
      createRouteExtras = {
        ...this.createRouteExtras,
      };
    }
    return {
      name: this.getCreateRouteName(),
      ...createRouteExtras,
    };
  }

  get prettyName() {
    if (this.modelClass.prettyName) {
      return this.modelClass.prettyName();
    } else {
      return this.objectType.charAt(0).toUpperCase() + this.objectType.slice(1);
    }
  }

  clearErrors() {
    this.errorMessages = [];
    this.error = false;
  }

  handleError(error: Error) {
    this.errorMessages = this.$errorHandler.errorToStrings(error);
    this.error = true;
    this.$errorHandler.handleError(error, false);
  }
}
