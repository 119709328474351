import { DeviceState } from '@/models/device/models';
import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base';

export const DEVICE_STATE: DeviceState[] = [
  {
    ...TRANSIENT_BASE_OBJECT_DEFAULT,
    id: '491df553-62ed-44e0-bcd1-2b1dcb9b1efc',
    create_time: '2019-06-05T15:26:18.628530+02:00',
    creator: null,
    application: '202f8fdb-4a2c-4a63-bc28-4eae93fde7a8',
    device: '512dce17-3991-4a4f-b620-18bd2f7a18b7',
    data: {
      reconfiguration_time: '2018-11-27T21:43:47.374241+00:00',
      last_time_sync: '2018-12-01T03:00:12.263468+00:00',
      last_position_markers: { acc: 98582, sys: 98581, button: 0 },
      major_sequence_number: 2,
      last_sync: '2018-12-01T06:00:57.688390+00:00',
      activated: true,
      force_reconfigure: false,
      accelerometer_settings_last_modified: '2018-11-07T17:13:19.575287Z',
      last_connection: '2018-12-01T06:00:55.448519+00:00',
      last_attempt: '2018-12-01T06:06:00.942129+00:00',
      readout_state: null,
    },
  },
  {
    ...TRANSIENT_BASE_OBJECT_DEFAULT,
    id: '491df553-62ed-44e0-bcd1-2b1dcb9b1efc',
    create_time: '2019-06-05T15:26:18.628530+02:00',
    creator: null,
    application: '202f8fdb-4a2c-4a63-bc28-4eae93fde7a8',
    device: '80b260d1-d9b7-49f3-bb39-36dbfd8ee683',
    data: {
      // set to future for testing
      last_health_check: '2020-06-22T10:11:40.942129+00:00',
      force_update_check: false,
      last_healthy_startup: '2019-07-11T12:47:09.853287+00:00',
      last_update_check: '2019-07-11T12:47:10.872651+00:00',
      last_startup: '2019-07-11T12:47:09.516757+00:00',
      last_update_check_success: false,
    },
  },
];
