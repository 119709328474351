import { DELIVERY_METHODS } from './delivery_methods';
import { DeliveryProcedure } from '@/models/firmware/models';
import { LifeCycleState } from '@/models/core/base';

export const DELIVERY_PROCEDURE_STAGES = {
  'builtin-download-local': {
    plugin_id: 'builtin-download-local',
    config: {
      version: 1,
      content_key: 'input',
      mime_type: 'application/octet-stream',
      expire_in: 5,
    },
  },
};

export const DELIVERY_PROCEDURES: DeliveryProcedure[] = [
  {
    id: 'dev_proc_0',
    object_state: LifeCycleState.Approved,
    name: 'Delivery Procedure A',
    comment: 'Small comment',
    id_text: 'Del Proc A',
    config_valid: true,
    device_options: {
      required: false,
      autotracking: false,
    },
    parameters: {
      name: {
        description: 'description',
      },
    },
    update_options: {
      expiration_policy: {
        ready: 10,
        up_to_date: 10,
      },
      auto_start: false,
      enable_confirm_step: false,
      confirm_timeout: 10,
      concurrency_limit_retry_after: 60,
      max_concurrent_pipelines: 5,
    },
    delivery_method: DELIVERY_METHODS[0].id,
    stages: [DELIVERY_PROCEDURE_STAGES['builtin-download-local']],
    _permissions: {
      edit: true,
      view: true,
    },
  },
];
