









import Vue from 'vue';
import Component from 'vue-class-component';
import { Role } from '@/models/core/models.ts';

@Component({})
export default class RoleList extends Vue {
  Role = Role;
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
