// env variables in separate file to avoid circular dependency problems
// do NOT import anything here

// environment variables are always strings
enum ENV_BOOLEAN {
  TRUE = 'true',
  FALSE = 'false',
}

export enum LocaleOption {
  EN = 'en',
  DE = 'de',
}

export enum THEME {
  LEITWERT = 'leitwert',
  USB = 'usb',
}

// env variables also have to be declared in vue.config.js
declare let process: {
  env: {
    NODE_ENV: string;
    DMS_FRONTEND_THEME?: THEME;
    DMS_DEFAULT_LOCALE?: LocaleOption;
    DMS_LOCALE_SWITCHER?: ENV_BOOLEAN;
    OPTION_USB?: ENV_BOOLEAN;
    OPTION_EULACH?: ENV_BOOLEAN;
  };
};

export const NODE_ENV: string = process.env.NODE_ENV ?? 'development';

export const DMS_FRONTEND_THEME: THEME =
  process.env.DMS_FRONTEND_THEME ?? THEME.LEITWERT;

export const DMS_DEFAULT_LOCALE: LocaleOption =
  process.env.DMS_DEFAULT_LOCALE ?? LocaleOption.EN;

export const DMS_LOCALE_SWITCHER: boolean =
  process.env.DMS_LOCALE_SWITCHER === ENV_BOOLEAN.TRUE;

export const OPTION_USB: boolean = process.env.OPTION_USB === ENV_BOOLEAN.TRUE;

export const OPTION_EULACH: boolean =
  process.env.OPTION_EULACH === ENV_BOOLEAN.TRUE;

if (NODE_ENV === 'development') {
  console.log('DMS_FRONTEND_THEME', DMS_FRONTEND_THEME);
  console.log('DMS_DEFAULT_LOCALE', DMS_DEFAULT_LOCALE);
  console.log('DMS_LOCALE_SWITCHER', DMS_LOCALE_SWITCHER);
  console.log('OPTION_USB', OPTION_USB);
  console.log('OPTION_EULACH', OPTION_EULACH);
}
