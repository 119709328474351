import { Organisation } from '@/models/core/organisation';

import { LifeCycleState } from '@/models/core/base';

export const ORGANISATIONS: Organisation[] = [
  {
    id: 'leitwert',
    object_state: LifeCycleState.Approved,
    name: 'Leitwert',
    password_policy: {
      version: 1,
      min_length: 5,
      alpha_upper: true,
      alpha_lower: true,
      numeric: false,
      special_char: false,
    },
    lifecycle_policy: {
      enable_reviews: false,
      require_reviewer: false,
    },
    avatar: 'logo.png',
    _permissions: {
      edit: true,
      view: true,
    },
    slug: 'leitwert-08jqjioh',
    slug_unique: '08jqjioh',
  },
  {
    id: 'org1',
    object_state: LifeCycleState.Approved,
    name: 'Orga One',
    password_policy: {
      version: 1,
      min_length: 5,
      alpha_upper: true,
      alpha_lower: true,
      numeric: false,
      special_char: false,
    },
    lifecycle_policy: {
      enable_reviews: false,
      require_reviewer: false,
    },
    avatar: 'logo.png',
    _permissions: {
      edit: true,
      view: true,
    },
    slug: 'org1',
    slug_unique: 'org1-12345678',
  },
  {
    id: 'org2',
    object_state: LifeCycleState.Approved,
    name: 'Orga2',
    password_policy: {
      version: 1,
      min_length: 5,
      alpha_upper: true,
      alpha_lower: true,
      numeric: false,
      special_char: false,
    },
    lifecycle_policy: {
      enable_reviews: false,
      require_reviewer: false,
    },
    avatar: 'logo.png',
    _permissions: {
      edit: true,
      view: true,
    },
    slug: 'org2',
    slug_unique: 'org2-abcdefgh',
  },
];
