
















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import BaseForm from '@/components/common/BaseForm.vue';
import AvatarUploadForm from '@/components/common/AvatarUploadForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { CommonContext } from '@/store/GlobalStore';
import { Profile, PROFILE_DEFAULT } from '@/models/core/profile';
import TokenList from './TokenList.vue';
import TokenForm from './TokenForm.vue';
import { adminRouteName } from '../../app';
import { Group } from '@/models/core/models';
import { deepCopy } from '@/util/util';
import { Filter } from '@/api/ApiClientV2';
import { RawLocation } from 'vue-router';
import { authStore } from '@/store/modules/auth/auth';

@Component({
  components: {
    TokenForm,
    TokenList,
    BaseForm,
    AvatarUploadForm,
  },
  computed: {
    ...mapState('global', ['context']),
  },
  mixins: [BeforeLeaveGuard],
})
export default class UserForm extends Vue {
  @Prop({ default: '' }) id!: string;
  @Prop({ default: 'human' }) kind!: string;

  $refs!: {
    baseForm: BaseForm;
    uploadForm: AvatarUploadForm;
  };
  context!: CommonContext;
  user: Profile = deepCopy(PROFILE_DEFAULT);
  loading = true;
  canAddToken = false;
  repeatPassword = '';
  originalAvatar: string | null = '';
  shouldRemoveAvatar = false;
  Group = Group;

  adminRouteName = adminRouteName;

  mounted(): void {
    this.loading = true;

    this.$api.get('profile', this.profileId).then(response => {
      this.originalAvatar = response.avatar;
      this.user = response;
      if (this.profileId === '0') {
        this.user.kind = this.kind;
      }
      this.loading = false;
      this.canAddToken = response._permissions.add_token;
    });
  }

  get filter(): Filter {
    return {
      order_by: 'name',
      profile: this.id,
    };
  }

  get profileId(): string {
    if (this.id === '') {
      // when we are entering the component via 'user-profile' route which does not have id parameter
      return authStore.profile?.id ?? '';
    } else {
      return this.id;
    }
  }

  refreshAvatar(): void {
    if (this.profileId === authStore.profile?.id) {
      authStore.initializeProfile(this.profileId);
    }
  }

  removeAvatar(): void {
    this.shouldRemoveAvatar = true;
    this.originalAvatar = null;
  }

  get transformedUser(): Profile {
    if (this.profileId === '0') {
      this.user.organisation = this.context.selection.organisation?.id;
    }
    if (this.shouldRemoveAvatar) {
      this.user.avatar = null;
    } else if (this.$refs.uploadForm !== undefined) {
      if (this.$refs.uploadForm.avatar) {
        this.user.avatar = this.$refs.uploadForm.avatar;
      }
    }
    return this.user;
  }

  kindChanged(): void {
    if (this.isMachine) {
      this.user.email = '';
      this.user.first_name = '';
      this.user.last_name = '';
    }
  }

  get isOwnProfile(): boolean {
    return this.profileId === authStore.profile?.id;
  }

  get canResetPin(): boolean {
    return (
      this.profileId !== '0' &&
      !this.isMachine &&
      !this.isOwnProfile &&
      this.user._permissions &&
      this.user._permissions.reset_pin_counter
    );
  }

  get canUpdate2fa(): boolean {
    return (
      !this.isMachine &&
      !this.isOwnProfile &&
      (this.profileId === '0' || this.user._permissions?.update_2fa)
    );
  }

  get isMachine(): boolean {
    return this.user.kind === 'machine';
  }

  resetPinCounters(): void {
    this.$api
      .resetPinCounters(this.user)
      .then(() => {
        this.$buefy.toast.open({
          message: 'Successfully reset!',
          type: 'is-success',
        });
      })
      .catch(error => {
        this.$refs.baseForm.handleError(error);
      });
  }

  get completedLocation(): RawLocation {
    if (this.$route.params.app_handle) {
      return {
        name: adminRouteName('profile-list'),
      };
    } else {
      return '/';
    }
  }
}
