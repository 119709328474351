import store from '@/store/index';
import { Location, Route } from 'vue-router';
import { ClientApp } from '@/models/client/models';
import { globalStore } from '@/store/modules/global';
import { authStore } from '@/store/modules/auth/auth';

export function beforeEnterOrganisations(to: Route): Promise<void | Location> {
  return store
    .dispatch('global/updateContextFilter', {
      objectType: 'organisation',
      filter: {},
    })
    .then(
      (): Promise<void | Location> => {
        if (to.params.org_slug) {
          // an organisation slug was given, try to select organisation
          return store
            .dispatch('global/selectContextObjectByProperty', {
              objectType: 'organisation',
              property: 'slug',
              value: to.params.org_slug,
            })
            .then(() => {
              // successfully looked up organisation via slug
              return;
            });
        } else {
          // no organisation is given in the slug. use the organisation id
          // from the user profile
          const profileOrgId = authStore.profile?.organisation;
          if (profileOrgId) {
            return store
              .dispatch('global/findObjectByProperty', {
                objectType: 'organisation',
                property: 'id',
                value: profileOrgId,
              })
              .then(organisation => {
                // redirecting with profile organisation
                return {
                  name: 'organisation',
                  params: { org_slug: organisation.slug },
                  replace: true,
                };
              });
          } else {
            return Promise.reject('No organisation found');
          }
        }
      },
      () => {
        throw new Error('Could not load organisation context');
      },
    );
}

export function beforeEnterOrganisation(to: Route): Promise<void | Location> {
  globalStore.setSelectedObject({
    objectType: 'organisation',
    object: store.getters['global/object']('organisation'),
  });
  return store
    .dispatch('global/updateContextFilter', {
      objectType: 'client-app',
      filter: {
        organisation: store.getters['global/object']('organisation').id,
      },
    })
    .then(
      (): Promise<void | Location> => {
        if (to.params.app_handle) {
          // an app handle was given, try to select from apps
          return store
            .dispatch('global/selectContextObjectByProperty', {
              objectType: 'client-app',
              property: 'handle',
              value: to.params.app_handle,
            })
            .then(() => {
              // successfully looked up client app via handle
              // TODO: do this in new store
              globalStore.setSelectedObject({
                objectType: 'clientApp',
                object: store.getters['global/selectedClientApp'],
              });
              return;
            });
        } else if (
          to.matched.find(match => match.name === 'routes-without-client-app')
        ) {
          // allow links that do not need a client app
          return;
        } else {
          // no app given, select first app
          return store.dispatch('global/getFirstClientApp').then(
            clientApp => {
              // redirecting
              return {
                name: clientApp.view_id,
                params: {
                  org_slug: to.params.org_slug,
                  app_handle: clientApp.handle,
                },
                replace: true,
              };
            },
            () => {
              // could not get first client app, clear selection
              return store.dispatch('global/selectContextObjectByProperty', {
                objectType: 'client-app',
                property: 'handle',
                value: undefined,
              });
            },
          );
        }
      },
    );
}

/**
 * Check that the 'to' route really belongs to the currently selected client
 * application.
 * @param to route being navigated to
 */
export function requireValidClientAppRoute(
  to: Route,
): Promise<void | Location> {
  const clientApp: ClientApp = store.getters['global/object']('client-app');
  if (to.matched.some(match => match.name === clientApp.view_id)) {
    return Promise.resolve();
  } else {
    return Promise.resolve({
      name: clientApp.view_id,
      params: {
        org_slug: to.params.org_slug,
        app_handle: clientApp.handle,
      },
      replace: true,
    });
  }
}
