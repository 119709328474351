































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getModelClass } from '@/models/objectRegistry';
import { ModelClass } from '@/models/core/base';
import { Attachment } from '@/models/core/models';

@Component({
  components: {
    VueCropper,
  },
  props: {
    originalDownloadUrl: {
      required: true,
    },
    isActive: {
      required: true,
    },
  },
  data() {
    return {
      cropImg: '',
    };
  },
})
export default class CropModal extends Vue {
  modelClass: ModelClass;
  $refs: {
    cropper: any;
  };
  cropImg: string;

  mounted() {}

  cancel() {
    this.$emit('cancel');
  }

  rotate() {
    this.$refs.cropper.rotate(90);
    // Zoom out so that the whole image is in view after rotating
    this.$refs.cropper.zoomTo(0);
  }

  zoomIn() {
    this.$refs.cropper.relativeZoom(0.1);
  }

  zoomOut() {
    this.$refs.cropper.relativeZoom(-0.1);
  }

  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  askCropImage() {
    this.$buefy.dialog.confirm({
      message:
        'Sind Sie sicher, dass Sie den Bildausschnitt überschreiben wollen?',
      onConfirm: () => this.cropImage(),
    });
  }

  async cropImage() {
    // get image data for post processing, e.g. upload or setting image src
    this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    const blob = this.dataURLtoBlob(this.cropImg);
    this.$emit('save', blob);
  }
}
