

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { BaseListColumn, ListModelField } from '@/models/core/base';
import { Context, Filter } from '@/api/ApiClientV2';
import { DeviceRelation } from '@/models/data/models';

@Component({})
export default class DeviceRelationSelectionList extends Vue {
  @Prop({ required: true }) selectedDevices!: string[];
  @Prop({ required: true }) appId!: string;
  @Prop({ required: true }) role!: string;

  DeviceRelation = DeviceRelation;

  get columns(): BaseListColumn[] {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
      },
      {
        key: 'device_device_id',
      },
      {
        key: 'role',
      },
    ];
    return DeviceRelation.defaultColumns(Device.langPath, listFields);
  }

  get collectionFilter(): Filter {
    return {
      application: this.appId,
      role: this.role,
    };
  }

  async selectAll(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const context: Context = {
        filter: this.collectionFilter,
        pagination: {
          page: 1,
          pageSize: 500,
        },
      };
      const response = await this.$apiv2.getList<DeviceRelation>(
        DeviceRelation,
        context,
      );
      if (response.size === 500) {
        this.$buefy.toast.open({
          message: 'Only first 500 selected',
          type: 'is-warning',
        });
      }
      const selection = response.results.map(d => d.id);
      this.$emit('row-selection', selection);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
