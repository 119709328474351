






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import { getModelClass } from '@/models/objectRegistry';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import UploadForm, { UploadState } from '@/components/common/UploadForm.vue';
import { ModelClass } from '@/models/core/base';
import { Attachment } from '@/models/core/models';
import CropModal from './CropModal.vue';
import { deepCopy } from '@/util/util';
import { authStore } from '@/store/modules/auth/auth';

@Component({
  name: 'formly_image-crop-field',
  components: {
    VueCropper,
    UploadForm,
    CropModal,
  },
  props: ['form', 'field', 'model', 'to'],
  data() {
    return {
      modelClass: getModelClass(this.$props.field.modelClass),
      cropImg: '',
      processedDownloadUrl: null,
      originalDownloadUrl: null,
      cropModalActive: false,
    };
  },
})
export default class ImageCropField extends Vue {
  modelClass: ModelClass;
  $refs: {
    cropper: any;
  };
  cropImg: string;
  processedDownloadUrl: string;
  originalDownloadUrl: string;
  loadingComponent: any;
  cropModalActive: boolean;

  mounted() {
    this.loadingComponent = this.$buefy.loading.open({});
    if (this.$props.model.id !== '0') {
      if (this.$props.model[this.$props.field.key]) {
        this.processedDownloadUrl = `${this.$props.field.processedDownloadUrl.replace(
          '%',
          this.$props.model.id,
        )}?${Math.random()}`;
      }
      if (this.$props.model[this.$props.field.original_key]) {
        this.originalDownloadUrl = `${this.$props.field.originalDownloadUrl.replace(
          '%',
          this.$props.model.id,
        )}?${Math.random()}`;
      }
    }
    this.loadingComponent.close();
  }

  updatedValue(value: UploadState[]) {
    if (value.length > 0) {
      const image = value[0];
      if (image.success) {
        this.$props.model[this.$props.field.key] = image.response.id;
        this.$props.model[this.$props.field.original_key] = image.response.id;
      }
    }
  }

  rotate() {
    this.$refs.cropper.rotate(90);
    // Zoom out so that the whole image is in view after rotating
    this.$refs.cropper.zoomTo(0);
  }

  zoomIn() {
    this.$refs.cropper.relativeZoom(0.1);
  }

  zoomOut() {
    this.$refs.cropper.relativeZoom(-0.1);
  }

  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  askDeleteImage() {
    this.$buefy.dialog.confirm({
      message: 'Sind Sie sicher, dass Sie den Grundriss löschen wollen?',
      onConfirm: () => this.deleteImages(),
    });
  }

  async deleteImages() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.$props.model[this.$props.field.key] = null;
      this.$props.model[this.$props.field.original_key] = null;
      await this.$apiv2.update(this.modelClass, this.$props.model);

      this.originalDownloadUrl = null;
      this.processedDownloadUrl = null;

      this.$buefy.toast.open({
        message: 'Gelöscht.',
        type: 'is-success',
      });
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Fehler beim Löschen.',
        type: 'is-warning',
      });
    }
    loadingComponent.close();
  }

  @Watch('$props.model.original_file')
  async originalImageUpdated() {
    if (this.$props.model[this.$props.field.original_key] === null) {
      // File got deleted
      return;
    }
    this.loadingComponent = this.$buefy.loading.open({});
    try {
      await this.$apiv2.update(this.modelClass, this.$props.model);
      this.originalDownloadUrl = null;
      this.originalDownloadUrl = `${this.$props.field.originalDownloadUrl.replace(
        '%',
        this.$props.model.id,
      )}?${Math.random()}${this.$props.model[this.$props.field.original_key]}`;
      this.processedDownloadUrl = `${this.$props.field.processedDownloadUrl.replace(
        '%',
        this.$props.model.id,
      )}?${Math.random()}${this.$props.model[this.$props.field.key]}`;
    } catch (error) {
      this.$errorHandler.handleError(error, false);
      this.$buefy.toast.open({
        message: 'Fehler beim Hochladen.',
        type: 'is-warning',
      });
    }
    this.loadingComponent.close();
  }

  async cropImage(blob) {
    const loadingComponent = this.$buefy.loading.open({});
    const formData = new FormData();
    formData.append(this.$props.field.name, blob, 'processed_file.png');

    const axiosConfig = deepCopy(authStore.axiosConfig);
    axiosConfig.headers['Content-Type'] = 'multipart/form-data';
    try {
      const result = await axios.post(
        this.$props.field.uploadUrl,
        formData,
        axiosConfig,
      );
      if (result.status === 200) {
        this.$props.model[this.$props.field.key] = result.data.id;

        await this.$apiv2.update(this.modelClass, this.$props.model);

        this.processedDownloadUrl = `${this.$props.field.processedDownloadUrl.replace(
          '%',
          this.$props.model.id,
        )}?${Math.random()}${this.$props.model[this.$props.field.key]}`;

        this.$buefy.toast.open({
          message: 'Bild hochgeladen.',
          type: 'is-success',
        });
        this.cropModalActive = false;
      } else {
        throw new Error('');
      }
    } catch (err) {
      console.error(err);
      this.$buefy.toast.open({
        message: 'Fehler beim Hochladen.',
        type: 'is-warning',
      });
    }
    loadingComponent.close();
  }
}
