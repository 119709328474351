import { common } from '@/lang/en/common';
import { auth } from '@/lang/en/auth';
import { client } from '@/lang/en/client';
import { admin } from '@/lang/en/admin';
import { device } from '@/lang/en/device';
import { firmware } from '@/lang/en/firmware';
import { data } from '@/lang/en/data';
import { study } from '@/lang/en/study';
import { monitoring } from '@/lang/en/monitoring';

export const en = {
  common,
  auth,
  client,
  admin,
  device,
  firmware,
  data,
  study,
  monitoring,
};
