import { DeliveryMethod } from '@/models/firmware/models';
import { LifeCycleState } from '@/models/core/base';
import { MODELS } from '@/mock/models';

export const DELIVERY_METHODS: DeliveryMethod[] = [
  {
    id: 'dev_method_0',
    object_state: LifeCycleState.Approved,
    model: MODELS[0].id,
    name: 'Delivery Method A',
    description: 'Short Description',
    _permissions: {
      edit: true,
      view: true,
    },
  },
];
